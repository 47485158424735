import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ViewAnnouncementModal from './ViewAnnouncementModal';

const ResidentAnnouncement = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [viewedAnnouncements, setViewedAnnouncements] = useState([]);

    // Fetch announcements from the API when the component mounts
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/announcements`)
            .then((response) => setAnnouncements(response.data.announcements))
            .catch((error) => console.error('Error fetching announcements:', error));
    }, []);

    // Function to go to the next slide
    const nextSlide = () => {
        setCurrentSlide((prev) => (prev === announcements.length - 1 ? 0 : prev + 1));
    };

    // Auto slide effect using setInterval
    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(interval);
    }, [announcements.length]);

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? announcements.length - 1 : prev - 1));
    };

    // Function to open the modal with the selected announcement
    const handleAnnouncementClick = (announcement) => {
        setSelectedAnnouncement(announcement);
        setShowModal(true);

        // Mark only the clicked announcement as viewed
        setViewedAnnouncements((prevViewed) => {
            if (!prevViewed.includes(announcement._id)) {
                return [...prevViewed, announcement._id];
            }
            return prevViewed;
        });
    };

    return (
        <div className="relative w-full h-full" data-carousel="slide">
            {/* Update the height to h-[633px] */}
            <div className="relative h-[633px] overflow-hidden rounded-lg ">
                {announcements.length === 0 ? (
                    <p className="text-center text-gray-500">No announcements available</p>
                ) : (
                    announcements.map((announcement, index) => (
                        <div
                            key={announcement._id} // Use _id as the key
                            className={`absolute inset-0 transition duration-700 ease-in-out ${
                                currentSlide === index ? 'block' : 'hidden'
                            }`}
                            data-carousel-item
                            onClick={() => handleAnnouncementClick(announcement)}
                            style={{
                                backgroundColor: viewedAnnouncements.includes(announcement._id)
                                    ? 'rgba(255,255,255,0.6)'
                                    : 'rgba(0,0,0,0.6)',
                            }}
                        >
                            <img
                                src={
                                    announcement.attachments
                                        ? announcement.attachments // Directly use the URL stored in the database
                                        : 'image-placeholder.png'
                                }
                                className="absolute block w-full h-full object-cover cursor-pointer"
                                alt="Announcement"
                            />
                            <div className="absolute bottom-5 left-5 text-white bg-black bg-opacity-50 p-4 rounded-lg">
                                <h3 className="text-lg font-bold">{announcement.announcementCategory}</h3>
                                <p className="text-sm">{announcement.title}</p>
                                <p className="text-xs text-gray-300">
                                    {new Date(announcement.created_at).toLocaleString()}
                                </p>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Centered Navigation Dots */}
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-5 flex space-x-2 z-30">
                {announcements.map((_, index) => (
                    <button
                        key={index}
                        type="button"
                        className={`w-2 h-2 rounded-full ${
                            currentSlide === index ? 'bg-blue-600' : 'bg-white'
                        }`}
                        aria-current={currentSlide === index ? 'true' : 'false'}
                        aria-label={`Slide ${index + 1}`}
                        onClick={() => setCurrentSlide(index)}
                    ></button>
                ))}
            </div>

            {/* Vertically Centered Previous Button */}
            <button
                type="button"
                className="absolute left-0 top-1/2 transform -translate-y-1/2 z-30 flex items-center justify-center px-4 cursor-pointer group focus:outline-none"
                onClick={prevSlide}
            >
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
                    <svg
                        className="w-4 h-4 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 1 1 5l4 4"
                        />
                    </svg>
                    <span className="sr-only">Previous</span>
                </span>
            </button>

            {/* Vertically Centered Next Button */}
            <button
                type="button"
                className="absolute right-0 top-1/2 transform -translate-y-1/2 z-30 flex items-center justify-center px-4 cursor-pointer group focus:outline-none"
                onClick={nextSlide}
            >
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
                    <svg
                        className="w-4 h-4 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 9 4-4-4-4"
                        />
                    </svg>
                    <span className="sr-only">Next</span>
                </span>
            </button>

            {showModal && selectedAnnouncement && (
                <ViewAnnouncementModal
                    announcement={selectedAnnouncement}
                    attachment={selectedAnnouncement.attachments} // Pass the attachment URL
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
};

export default ResidentAnnouncement;
