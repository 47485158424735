import React from 'react';
import { Link } from 'react-router-dom';
import {  MdEditDocument, MdDescription, MdMessage, MdGroup, MdInfo, MdMap } from 'react-icons/md';
import { BiSolidMegaphone } from 'react-icons/bi';
import { FaHouse, FaSquarePhone } from 'react-icons/fa6';
import { AiFillInfoCircle } from 'react-icons/ai';
import { BsFillBuildingsFill } from "react-icons/bs";

const HomepageCards = ({ residentData }) => {
  const cardData = [
    {
      path: "/Resident/Announcements",
      icon: <BiSolidMegaphone size={50} />,
      title: "Announcement"
    },
    {
      path: "/Resident/Incident-Report",
      icon: <MdEditDocument size={50} />,
      title: "Incident Filing"
    },
    {
      path: "/Resident/Document-Request",
      icon: <MdDescription size={50} />,
      title: "Document Request"
    },
    {
      path: "/Resident/Messages",
      icon: <AiFillInfoCircle size={50} />,
      title: "Help Center"
    },
    {
      path: "/Resident/Barangay-Officials-Directory",
      icon: <MdGroup size={50} />,
      title: "Barangay Officials Directory"
    },
    {
      path: "/Resident/Barangay-Information",
      icon: <BsFillBuildingsFill size={50} />,
      title: "Barangay Information"
    },
    {
      path: "/Resident/EvacuationMap",
      icon: <MdMap size={50} />,
      title: "Evacuation Map"
    },
    {
      path: "/Resident/Emergency-Hotlines",
      icon: <FaSquarePhone size={50} />,
      title: "Emergency Hotlines"
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
      {cardData.map((card, index) => (
        <div key={index} className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <Link to={{ pathname: card.path, state: { residentData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:rounded hover:text-white">
              {card.icon}
              <span className="ml-4 font-semibold text-lg">{card.title}</span>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomepageCards;
