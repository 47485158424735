import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import defaultProfile from '../../../assets/icons/default-profile.png';
import ResidentNav from '../../../component/Resident/ResidentNav';
import ResidentHeader from '../../../component/Resident/ResidentHeader';
import ResidentChangePasswordModal from '../../../component/Resident/ResidentChangePasswordModal';

const ResidentProfile = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [residentData, setResidentData] = useState(null);
    const [householdHead, setHouseholdHead] = useState('');
    const [householdHeadContactNumber, setHouseholdHeadContactNumber] = useState('');
    const [householdMembers, setHouseholdMembers] = useState([]);
    const [showPresentAddress, setShowPresentAddress] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, '/'); // Adjust path for correct URL
            }
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? `${capitalizeWords(user.middleName.charAt(0))}.` : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setResidentData(user);
            setUserRole(user.roleinHousehold);

            // If the household ID exists, fetch the household details
            if (user.householdID) {
                fetchHouseholdById(user.householdID);
            }
        }
    }, []);


    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };
    const togglePresentAddress = () => {
        setShowPresentAddress(!showPresentAddress); // Toggle state
    };
    
       // Fetch household information
       const fetchHouseholdById = async (householdId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/id/${householdId}`);
            if (response.data) {
                const { householdHead, contactNumber, members } = response.data;
                setHouseholdHead(`${householdHead.firstName} ${householdHead.middleName ? householdHead.middleName.charAt(0) + '.' : ''} ${householdHead.lastName} ${householdHead.suffix}`);
                setHouseholdHeadContactNumber(contactNumber);
                setHouseholdMembers(members);
            } else {
                alert('No household information available');
                setHouseholdHead('');
                setHouseholdHeadContactNumber('');
                setHouseholdMembers([]);
            }
        } catch (error) {
            console.error('Error fetching household:', error.response || error.message);
            alert('Error fetching household information');
        }
    };

     // Function to get status classes based on account status
     const getStatusClasses = (status) => {
        switch (status) {
            case 'Pending':
                return 'bg-yellow-500 text-white';
            case 'Approved':
                return 'bg-[#1346AC] text-white';
            case 'Denied':
                return 'bg-red-500 text-white';
            default:
                return 'bg-gray-300 text-black'; // Default styling for unknown status
        }
    };

  return (
    <div className="flex flex-col min-h-screen scrollbar-thick overflow-y-auto h-64">
            <ResidentHeader 
                userName={userName} 
                userRole={userRole} 
                handleLogout={handleLogout} 
                profilePic={residentData?.profilepic} 
            />
            <div className="flex flex-1">
                <ResidentNav residentData={residentData} />
        {/* Profile Information on the right */}
            <div className="flex-1 p-6 bg-white shadow-md rounded-lg">
                    {/* User Profile Section */}
                    <div className="flex items-center mb-6">
                        <img
                            src={residentData?.profilepic || defaultProfile} 
                            alt="Profile"
                            className="w-24 h-24 rounded-full border-2 border-gray-300 mr-4"
                        />
                        <div className="flex flex-col w-auto">
                            <div className='flex'>
                            <h1 className="text-3xl font-bold">{userName}</h1>
                            </div>
                            <div className="flex justify-start rounded-full w-auto mt-2">
                                <h1 className={`font-semibold px-3 py-1 rounded-full ${getStatusClasses(residentData?.accountStatus)}`}>
                                    {residentData?.accountStatus}
                                </h1>
                            </div>
                        </div>
                    </div>

                    {/* Personal Information Section */}
                    <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <h2 className="text-lg font-bold mb-2">Personal Information</h2>
                            <p>Resident ID No: {residentData?.adminID}</p>
                            <p>Birthdate: {residentData?.birthday}</p>
                            <p>Sex: {residentData?.sex}</p>
                            <p>Age: {residentData?.age}</p>
                            <p>Birthplace: {residentData?.birthplace}</p>
                            <p>Occupation Status: {residentData?.occupationStatus}</p>
                            <p>Nationality: {residentData?.nationality}</p>
                            <p>Civil Status: {residentData?.civilStatus}</p>
                            <p>Email Address: {residentData?.email}</p>
                            <p>Contact Number: {residentData?.contactNumber}</p>
                        </div>
                    </div>

                    <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <h2 className="text-lg font-bold mb-2">Special Information</h2>
                            <p>PWD: {residentData?.pwd ? 'Yes' : 'No'}</p>
                            <p>Solo Parent: {residentData?.soloparent ? 'Yes' : 'No'}</p>
                            <p>Indigent: {residentData?.indigent ? 'Yes' : 'No'}</p>
                            <p>Senior Citizen: {residentData?.seniorCitizen ? 'Yes' : 'No'}</p>
                            <p>Indigenous People: {residentData?.indigenouspeople ? 'Yes' : 'No'}</p>
                            <p>4Ps Member: {residentData?.fourpsmember ? 'Yes' : 'No'}</p>
                            <p>Special Status: {residentData?.specialstatus ? 'Yes' : 'No'}</p>
                        </div>
                    </div>

                    {/* Permanent Address Section */}
                    <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <h2 className="text-lg font-bold mb-2">Permanent Address</h2>
                            <p>House No.: {residentData?.permanentAddress?.houseNo}</p>
                            <p>Street: {residentData?.permanentAddress?.street}</p>
                            <p>Unit/Floor/Room No.: {residentData?.permanentAddress?.unitFloorRoomNo || 'N/A'}</p>
                            <p>Building: {residentData?.permanentAddress?.building || 'N/A'}</p>
                            <p>Block No.: {residentData?.permanentAddress?.blockNo || 'N/A'}</p>
                            <p>Lot No.: {residentData?.permanentAddress?.lotNo || 'N/A'}</p>
                            <p>Phase No.: {residentData?.permanentAddress?.phaseNo || 'N/A'}</p>
                            <p>Subdivision: {residentData?.permanentAddress?.subdivision || 'N/A'}</p>
                            <p>Barangay: {residentData?.permanentAddress?.barangay || 'N/A'}</p>
                            <p>City/Municipality: {residentData?.permanentAddress?.city || 'N/A'}</p>
                            <p>Province: {residentData?.permanentAddress?.province || 'N/A'}</p>
                            <p>Region: {residentData?.permanentAddress?.region || 'N/A'}</p>
                        </div>
                    </div>

                      <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <div className="flex items-center justify-between">
                                <h2 className="text-lg font-bold mb-2">Present Address</h2>
                                <button 
                                onClick={togglePresentAddress} 
                                className="px-4 py-2 text-white bg-[#1346AC] hover:bg-blue-700 transition-colors duration-300 rounded-md shadow-md"
                                >
                                {showPresentAddress ? 'Hide' : 'Show'}
                                </button>
                            </div>
                            {showPresentAddress && (
                                <div>
                                    <p>House No.: {residentData?.presentAddress?.houseNo || 'N/A'}</p>
                                    <p>Street: {residentData?.presentAddress?.street || 'N/A'}</p>
                                    <p>Unit/Floor/Room No.: {residentData?.presentAddress?.unitFloorRoomNo || 'N/A'}</p>
                                    <p>Building: {residentData?.presentAddress?.building || 'N/A'}</p>
                                    <p>Block No.: {residentData?.presentAddress?.blockNo || 'N/A'}</p>
                                    <p>Lot No.: {residentData?.presentAddress?.lotNo || 'N/A'}</p>
                                    <p>Phase No.: {residentData?.presentAddress?.phaseNo || 'N/A'}</p>
                                    <p>Subdivision: {residentData?.presentAddress?.subdivision || 'N/A'}</p>
                                    <p>Barangay: {residentData?.presentAddress?.barangay || 'N/A'}</p>
                                    <p>City/Municipality: {residentData?.presentAddress?.city || 'N/A'}</p>
                                    <p>Province: {residentData?.presentAddress?.province || 'N/A'}</p>
                                    <p>Region: {residentData?.presentAddress?.region || 'N/A'}</p>
                                    <p>Postal Code: {residentData?.presentAddress?.postalcode || 'N/A'}</p>
                                </div>
                            )}
                        </div>
                    </div>
              {/* Household Information Section */}
              <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <h2 className="text-lg font-bold mb-2">Household Information</h2>
                            <p>Household Head: {householdHead || 'N/A'}</p>
                            <p>Household Head Contact: {householdHeadContactNumber || 'N/A'}</p>
                            <h3 className="mt-4 font-bold">Members:</h3>
                            <ul>
                                {householdMembers.length > 0 ? (
                                    householdMembers.map((member, index) => (
                                        <li key={index}>{member.firstName} {member.lastName} - {member.age} years old </li>
                                    ))
                                ) : (
                                    <p>No household members found.</p>
                                )}
                            </ul>
                        </div>
                    </div>


                     <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <h2 className="text-lg font-bold mb-2">Other Information</h2>
                            <p>PWD ID No.: {residentData?.pwdid_num}</p>
                            <p>Solo Parent No.: {residentData?.soloparentid_num}</p>
                            <p>Senior Citizen ID No.: {residentData?.seniorcitizenid_num}</p>
                            <p>PhilSys No.: {residentData?.philsys_num}</p>
                            <p>Voter's ID no: {residentData?.voters_id}</p>
                            <p>SSS: {residentData?.sss_num}</p>
                            <p>Pag-IBIG: {residentData?.pagibig_num}</p>
                            <p>PhilHealth: {residentData?.philhealth_num}</p>
                            <p>TIN: {residentData?.tin_num}</p>
                        </div>
                    </div>

                    {/* Update Info Button */}
                    <div className="flex flex-col items-center justify-center mt-6 space-y-2">
                    <button
                        className="bg-[#1346AC] hover:bg-blue-700 w-1/4 text-white font-bold py-2 px-6 rounded-lg"
                        onClick={() => setIsModalOpen(true)} 
                    >
                        Change Password
                    </button>
                    </div>
                </div>
                <ResidentChangePasswordModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)} 
                />
            </div>
          
        </div>
  )
}

export default ResidentProfile;
