import React, { useEffect, useState } from 'react';
import Navigation from '../../component/Navigation';
import Header from '../../component/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const EmergencyHotlines = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [adminData, setAdminData] = useState(null);
    const [hotlines, setHotlines] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        contactNo: '',
        photo: null
    });
    const [photoPreview, setPhotoPreview] = useState(''); // For previewing the selected photo
    const [errors, setErrors] = useState({
        name: '',
        contactNo: '',
        photo: ''
    });
    
    // Fetch hotlines on component mount
    useEffect(() => {
        fetchHotlines();
    }, []);
    
    const fetchHotlines = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/hotlines`);
            setHotlines(response.data);
        } catch (error) {
            console.error('Error fetching hotlines:', error);
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, '/');
            }
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? `${capitalizeWords(user.middleName.charAt(0))}.` : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setAdminData(user);
            setUserRole(user.roleinBarangay);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' }); // Clear error on input change
    };

    // Handle file input (photo upload)
    const handleFileChange = (e) => {
        if (e.target.files.length > 1) {
            setErrors({ ...errors, photo: 'Only one photo can be uploaded' });
        } else {
            const file = e.target.files[0];
            setFormData({ ...formData, photo: file });
            setPhotoPreview(URL.createObjectURL(file)); // Preview the selected photo
            setErrors({ ...errors, photo: '' }); // Clear error on file change
        }
    };

    // Handle form submission with validation
    const handleSubmit = async (e) => {
        e.preventDefault();

        let formIsValid = true;
        let newErrors = { ...errors };

        // Validate name length
        if (!formData.name || formData.name.length < 3) {
            formIsValid = false;
            newErrors.name = 'Name must be at least 3 characters long.';
        }

        // Validate contact number length
        if (!formData.contactNo || formData.contactNo.length < 7) {
            formIsValid = false;
            newErrors.contactNo = 'Contact number must be at least 7 characters long.';
        }

        // Validate photo input
        if (!formData.photo) {
            formIsValid = false;
            newErrors.photo = 'Photo is required.';
        }

        setErrors(newErrors);

        if (!formIsValid) {
            return;
        }

        const newFormData = new FormData();
        newFormData.append('name', formData.name);
        newFormData.append('contactNo', formData.contactNo);
        newFormData.append('photo', formData.photo);

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_API_KEY}/api/new/hotlines`, newFormData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            fetchHotlines(); // Refresh hotlines after adding a new one
            setFormData({ name: '', contactNo: '', photo: null }); // Clear the form
            setPhotoPreview(''); // Clear the photo preview
            document.querySelector('input[type="file"]').value = ''; // Reset file input
            Swal.fire({
                title: 'Success!',
                text: 'Hotline created successfully!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Error creating hotline. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            console.error('Error creating hotline:', error);
        }
    };

    return (
        <div className="flex flex-col min-h-screen scrollbar-thick overflow-y-auto h-64">
            <Header userName={userName} userRole={userRole} handleLogout={handleLogout} profilePic={adminData?.profilepic} />
            <div className="flex flex-1">
                <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
                <div className="flex-1 p-6">
                    <h2 className="text-2xl font-bold mb-4">Emergency Hotlines</h2>

                    {/* Form to add new hotline */}
                    <form className="mb-6" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-4 mb-4">
                            <div>
                                <label className="block text-md font-medium text-gray-700">Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    placeholder="Enter Hotline Name"
                                />
                                {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                            </div>

                            <div>
                                <label className="block text-md font-medium text-gray-700">Contact Number</label>
                                <input
                                    type="text"
                                    name="contactNo"
                                    value={formData.contactNo}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    placeholder="Enter Contact Number"
                                />
                                {errors.contactNo && <p className="text-red-500 text-sm mt-1">{errors.contactNo}</p>}
                            </div>

                            <div>
                                <label className="block text-md font-medium text-gray-700">Photo</label>
                                <input
                                    type="file"
                                    name="photo"
                                    onChange={handleFileChange}
                                    className="mt-1 block w-full text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                />
                                {errors.photo && <p className="text-red-500 text-sm mt-1">{errors.photo}</p>}
                            </div>
                        </div>

                        
                        {formData.photo && (
                            <div className="flex items-start justify-start mb-4">
                                <div className="relative flex items-center justify-between p-2 border rounded-lg shadow-sm bg-gray-50">
                                    <div className="flex items-center">
                                        <div className="w-10 h-10 bg-[#1346AC] text-white flex items-center justify-center rounded-full mr-3">
                                            <i className="fas fa-file-alt"></i>
                                        </div>
                                        <span className="text-blue-600 font-semibold truncate">
                                            {formData.photo.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}


                        <button
                            type="submit"
                            className="bg-[#1346AC] hover:bg-blue-700 text-white px-4 py-2 rounded transition duration-300"
                        >
                            Add Hotline
                        </button>
                    </form>

                    <h3 className="text-xl font-bold mb-4">Existing Hotlines</h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                        {hotlines.map(hotline => (
                            <div key={hotline._id} className="bg-white shadow-xl rounded-lg overflow-hidden flex p-4 min-h-[200px]" >
                                {/* Image on the left, centered vertically */}
                                <figure className="flex-shrink-0 flex items-center justify-center">
                                    <img 
                                        src={hotline.photo} 
                                        alt={hotline.name} 
                                        className="h-24 w-24 object-contain rounded-full"
                                    />
                                </figure>
                                
                                {/* Content on the right */}
                                <div className="flex flex-col justify-between flex-grow ml-4">
                                    <div>
                                        <h4 className="text-lg font-bold">{hotline.name}</h4>
                                        <p className="text-lg font-bold mt-4">Contact No: {hotline.contactNo}</p>
                                    </div>
                                    <div className="flex justify-end mt-auto">
                                        <button className="btn text-white bg-[#1346AC] hover:bg-blue-700">Edit</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmergencyHotlines;
