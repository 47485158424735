import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const BarangayProfilingTableModal = ({ filters = {}, residents, onClose }) => {
  
    const handleExportPDF = () => {
        // Initialize jsPDF with landscape orientation
        const doc = new jsPDF('landscape'); 
        doc.text('Barangay Profiling', 20, 10);

        // Add filters information to the PDF
        doc.text(
            `Filters: Sex: ${filters.sex}, Age Range: ${filters.ageRange}, Civil Status: ${filters.civilStatus}, Nationality: ${filters.nationality}, Religion: ${filters.religion}, Others: ${filters.voter} ${filters.indigent} ${filters.soloParent}`,
            20,
            20
        );

        // Add table to the PDF
        doc.autoTable({
            startY: 30,
            head: [['Resident No.', 'Name', 'Sex', 'Age', 'Civil Status', 'Contact Number', 'Address', 'Nationality', 'Religion', 'Voter', 'Indigent', 'Solo Parent', '4Ps', 'PWD']],
            body: residents.map((resident, index) => [
                index + 1,
                `${resident.firstName} ${resident.middleName} ${resident.lastName}`,
                resident.sex,
                resident.age,
                resident.civilStatus,
                resident.contactNumber,
                `${resident.permanentAddress.street}, ${resident.permanentAddress.barangay}, ${resident.permanentAddress.city}`,
                resident.nationality,
                resident.religion,
                resident.voter ? 'Yes' : '',
                resident.indigent ? 'Yes' : '',
                resident.soloParent ? 'Yes' : '',
                resident.fourps ? 'Yes' : '',
                resident.pwd ? 'Yes' : '',
            ]),
            theme: 'grid',  // Ensures table has grid lines
            styles: { fontSize: 10, cellPadding: 2 },  // Adjust font size and padding for better readability
            margin: { top: 30 },  // Set margin for content
        });

        doc.save('barangay_profiling.pdf');
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg p-6 shadow-lg w-auto max-h-auto overflow-y-auto">
                <h2 className="text-2xl font-bold mb-4">Barangay Profiling</h2>

                <div className="flex justify-between mb-4">
                    <div>Sex: {filters.sex}</div>
                    <div>Age Range: {filters.ageRange}</div>
                    <div>Civil Status: {filters.civilStatus}</div>
                    <div>Nationality: {filters.nationality}</div>
                    <div>Religion: {filters.religion}</div>
                    <div>Others: {filters.voter} {filters.indigent} {filters.soloParent}</div>
                </div>

                <table className="min-w-full border border-gray-300">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="py-2 px-4 border">Resident No.</th>
                            <th className="py-2 px-4 border">Name</th>
                            <th className="py-2 px-4 border">Sex</th>
                            <th className="py-2 px-4 border">Age</th>
                            <th className="py-2 px-4 border">Civil Status</th>
                            <th className="py-2 px-4 border">Contact Number</th>
                            <th className="py-2 px-4 border">Address</th>
                            <th className="py-2 px-4 border">Nationality</th>
                            <th className="py-2 px-4 border">Religion</th>
                            <th className="py-2 px-4 border">Voter</th>
                            <th className="py-2 px-4 border">Indigent</th>
                            <th className="py-2 px-4 border">Solo Parent</th>
                            <th className="py-2 px-4 border">4Ps</th>
                            <th className="py-2 px-4 border">PWD</th>
                        </tr>
                    </thead>
                    <tbody>
                        {residents.map((resident, index) => (
                            <tr key={resident.residentID}>
                                <td className="py-2 px-4 border text-center">{index + 1}</td>
                                <td className="py-2 px-4 border">{`${resident.firstName} ${resident.middleName} ${resident.lastName}`}</td>
                                <td className="py-2 px-4 border text-center">{resident.sex}</td>
                                <td className="py-2 px-4 border text-center">{resident.age}</td>
                                <td className="py-2 px-4 border text-center">{resident.civilStatus}</td>
                                <td className="py-2 px-4 border">{resident.contactNumber}</td>
                                <td className="py-2 px-4 border">{`${resident.permanentAddress.street}, ${resident.permanentAddress.barangay}, ${resident.permanentAddress.city}`}</td>
                                <td className="py-2 px-4 border">{resident.nationality}</td>
                                <td className="py-2 px-4 border">{resident.religion}</td>
                                <td className="py-2 px-4 border text-center">{resident.voter ? '✔' : ''}</td>
                                <td className="py-2 px-4 border text-center">{resident.indigent ? '✔' : ''}</td>
                                <td className="py-2 px-4 border text-center">{resident.soloParent ? '✔' : ''}</td>
                                <td className="py-2 px-4 border text-center">{resident.fourps ? '✔' : ''}</td>
                                <td className="py-2 px-4 border text-center">{resident.pwd ? '✔' : ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="flex justify-between mt-4">
                    <span>Total Count: {residents.length}</span>
                    <div>
                        <button className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold mr-2" onClick={handleExportPDF}>
                            Export List as PDF
                        </button>
                        <button className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BarangayProfilingTableModal;
