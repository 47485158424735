import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for fetching data
import VicinityMap from '../../../assets/images/vicinity.png';
import ResidentHeader from '../../../component/Resident/ResidentHeader';
import ResidentNav from '../../../component/Resident/ResidentNav';

const ResidentBarangayInformation = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [residentData, setResidentData] = useState(null);
    const [barangay, setBarangay] = useState(null); // State to hold barangay data

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, '/'); // Adjust path for correct URL
            }
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? `${capitalizeWords(user.middleName.charAt(0))}.` : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setResidentData(user);
            setUserRole(user.roleinHousehold);
        }
    }, []);

    useEffect(() => {
        const fetchBarangay = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/barangay`); // Fetch the only barangay
                setBarangay(response.data.barangay);
            } catch (error) {
                console.error('Error fetching barangay data:', error);
            }
        };

        fetchBarangay(); // Fetch barangay data when the component mounts
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    return (
        <div className="flex flex-col min-h-screen">
            <ResidentHeader 
                userName={userName} 
                userRole={userRole} 
                handleLogout={handleLogout} 
                profilePic={residentData?.profilepic} 
            />
            <div className="flex flex-1">
                <ResidentNav residentData={residentData} />
                <main className="flex-1 p-6 bg-gray-100">
                    <div className="flex items-center mb-7">
                        <h1 className="text-4xl font-bold">Barangay Information</h1>
                    </div>
                    <div className="flex justify-center items-center space-x-4">
                        <div className="bg-white flex flex-col p-6 rounded-lg shadow-md w-1/4 space-y-5">
                            <div className='flex w-full items-center justify-center'>
                                <img src={barangay?.logo} alt="Barangay Logo" className="w-64 h-64 object-cover mb-4" />
                            </div>
                            <div>
                                <h2 className="font-bold text-lg">Barangay</h2>
                                <p>{barangay?.barangayName || 'Loading...'}</p>
                            </div>
                            <div>
                                <h2 className="font-bold text-lg">Municipality</h2>
                                <p>{barangay?.municipality || 'Loading...'}</p>
                            </div>
                            <div>
                                <h2 className="font-bold text-lg">Province</h2>
                                <p>{barangay?.province || 'Loading...'}</p>
                            </div>
                            <div>
                                <h2 className="font-bold text-lg">Email</h2>
                                <p>{barangay?.email || 'Loading...'}</p>
                            </div>
                            <div>
                                <h2 className="font-bold text-lg">Phone</h2>
                                <p>{barangay?.contactnumber || 'Loading...'}</p>
                            </div>
                        </div>

                        {/* Additional Information Section */}
                        <div className="bg-white flex flex-col p-6 rounded-lg shadow-md w-3/4">
                            <h2 className="text-2xl font-semibold mb-6">Barangay Information</h2>

                            {/* History Section */}
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold mb-1">History</h3>
                                <p>{barangay?.history || 'Loading...'}</p>
                            </div>

                            {/* Location Section */}
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold mb-1">Location</h3>
                                <p>{barangay?.location || 'Loading...'}</p>
                            </div>

                            {/* Population Section */}
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold mb-1">Population</h3>
                                <p>
                                    The household population of Barangay 52-Ipil in the 2015 Census was 1,095, broken down into 306 households or an average of 3.58 members per household. Its population as determined by the 2020 Census was 1,174. This represented 1.17% of the total population of Cavite City.
                                    <br />
                                    The registered resident in the database {barangay?.population === 1 ? 'is' : 'are'} {barangay?.population || 'Loading...'} and the total household registered in the database {barangay?.household === 1 ? 'is' : 'are'} {barangay?.household || 'Loading...'}.
                                </p>
                            </div>

                            {/* Vicinity Map Section */}
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold mb-1">Vicinity Map</h3>
                                <img src={VicinityMap} alt="Vicinity Map" className="min-w-[450px] max-h-fit h-auto object-cover" />
                            </div>

                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ResidentBarangayInformation;
