import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/Header';
import Navigation from '../../component/Navigation';
import Nationalities from '../../assets/dropdowns/Nationalities';
import Religions from '../../assets/dropdowns/Religions';
import axios from 'axios';
import BarangayProfilingTableModal from '../../component/BarangayProfilingTableModal';

const BarangayProfiling = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [adminData, setAdminData] = useState(null);


     // Filters State
     const [profilingYear, setProfilingYear] = useState('');
     const [sex, setSex] = useState('');
     const [ageRange, setAgeRange] = useState({ from: '', to: '' });
     const [civilStatus, setCivilStatus] = useState('');
     const [nationality, setNationality] = useState(''); 
     const [religion, setReligion] = useState(''); 
     const [voter, setVoter] = useState(false);
     const [indigent, setIndigent] = useState(false);
     const [soloParent, setSoloParent] = useState(false);
     const [fourps, setFourPs] = useState(false);
     const [pwd, setPWD] = useState(false);

    const [filteredResidents, setFilteredResidents] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? capitalizeWords(user.middleName.charAt(0)) + '.' : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setAdminData(user);
            setUserRole(user.roleinBarangay);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

     // Build Query Parameters for the API Call
     const buildQueryParams = () => {
        let params = {};

        if (profilingYear) params.profilingYear = profilingYear;
        if (sex) params.sex = sex;
        if (ageRange.from || ageRange.to) {
            if (ageRange.from) params.ageFrom = ageRange.from;
            if (ageRange.to) params.ageTo = ageRange.to;
        }
        if (civilStatus) params.civilStatus = civilStatus;
        if (nationality) params.nationality = nationality;
        if (religion) params.religion = religion;
        if (voter) params.voter = voter;
        if (indigent) params.indigent = indigent;
        if (soloParent) params.soloParent = soloParent;
        if (fourps) params.fourps = fourps;
        if (pwd) params.pwd = pwd;

        return params;
    };

    const handleViewList = async () => {
        try {
            const query = buildQueryParams();
             // Log the query in the browser console
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/filter/residents`, { params: query });
             // Log the response to ensure residents are returned
            
            if (response.data.residents.length === 0) {
                console.log('No residents found for the given filters.');
            }
            
            setFilteredResidents(response.data.residents); // Assuming the API returns filtered residents
            setShowModal(true); // Show the modal with filtered data
        } catch (error) {
            console.error('Error fetching filtered residents:', error);
        }
    };    

    return (
        <div className="flex flex-col min-h-screen">
            <Header userName={userName} userRole={userRole} handleLogout={handleLogout} />
            <div className="flex flex-1">
                <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
                <main className="flex-1 p-6 bg-gray-100">
                    <div className="flex items-center mb-7">
                        <h1 className="text-4xl font-bold">Barangay Profiling</h1>
                    </div>
                    <div className="flex justify-evenly items-center space-x-10">
                        <div className="bg-white flex flex-col p-6 rounded-lg shadow-md w-1/3">
                            <h2 className="text-2xl font-bold mb-4">Filter List</h2>
                            <div className="mb-4">
                                <label className="block mb-1">Profiling Year</label>
                                <select 
                                    className="border border-gray-300 rounded-md p-2 w-full"
                                    value={profilingYear}
                                    onChange={(e) => setProfilingYear(e.target.value)}
                                >
                                    <option>Select Profiling Year</option>
                                    <option>2024</option>
                                    <option>2025</option>
                                    <option>2026</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-1">Sex</label>
                                <select 
                                    className="border border-gray-300 rounded-md p-2 w-full"
                                    value={sex}
                                    onChange={(e) => setSex(e.target.value)}
                                >
                                    <option>Select Sex</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-1">Age Range</label>
                                <div className="flex">
                                    <input 
                                        type="number" 
                                        className="border border-gray-300 rounded-md p-2 w-1/2 mr-2" 
                                        placeholder="From" 
                                        value={ageRange.from}
                                        onChange={(e) => setAgeRange(prev => ({ ...prev, from: e.target.value }))} 
                                    />
                                    <input 
                                        type="number" 
                                        className="border border-gray-300 rounded-md p-2 w-1/2" 
                                        placeholder="To" 
                                        value={ageRange.to}
                                        onChange={(e) => setAgeRange(prev => ({ ...prev, to: e.target.value }))} 
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-1">Civil Status</label>
                                <select 
                                    className="border border-gray-300 rounded-md p-2 w-full"
                                    value={civilStatus}
                                    onChange={(e) => setCivilStatus(e.target.value)}
                                >
                                    <option>Select Civil Status</option>
                                    <option>Single</option>
                                    <option>Married</option>
                                    <option>Separated</option>
                                    <option>Annulled</option>
                                    <option>Divorced</option>
                                    <option>Widowed</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <Nationalities
                                    selectedNationality={nationality}
                                    handleChange={(e) => setNationality(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <Religions
                                    selectedReligion={religion}
                                    handleChange={(e) => setReligion(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-wrap mb-4">
                                <label className="flex items-center">
                                    <input type="checkbox" checked={voter} onChange={() => setVoter(!voter)} />
                                    <span className="ml-2">Voter</span>
                                </label>
                                <label className="flex items-center ml-4">
                                    <input type="checkbox" checked={indigent} onChange={() => setIndigent(!indigent)} />
                                    <span className="ml-2">Indigent</span>
                                </label>
                                <label className="flex items-center ml-4">
                                    <input type="checkbox" checked={fourps} onChange={() => setFourPs(!fourps)} />
                                    <span className="ml-2">4Ps</span>
                                </label>
                                <label className="flex items-center ml-4">
                                    <input type="checkbox" checked={soloParent} onChange={() => setSoloParent(!soloParent)} />
                                    <span className="ml-2">Solo Parent</span>
                                </label>
                                <label className="flex items-center ml-4">
                                    <input type="checkbox" checked={pwd} onChange={() => setPWD(!pwd)} />
                                    <span className="ml-2">PWD</span>
                                </label>
                            </div>
                            <div className="flex flex-col w-full px-6 space-y-2">
                                <button 
                                    className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold"
                                    onClick={handleViewList}
                                >
                                    View List
                                </button>
                                </div>
                            </div>
                        <div className="bg-white flex flex-col p-6 rounded-lg shadow-md w-2/3">
                        <h2 className="text-2xl font-bold mb-4">Charts</h2>
                        <select
                            name="status"
                            className="border border-gray-400 rounded-md p-2 w-full"
                        >
                            <option value="Select Chart" className='text-gray-200'>Select Chart</option>
                            <option value="Pie">Age Distribution Chart</option>
                            <option value="Active">Active</option>
                            <option value="Scheduled">Scheduled</option>
                            <option value="Settled">Settled</option>
                        </select>
                        <h3 className='text-center font font-semibold p-4 text-lg'> Age Distribution Chart </h3>
                        
                        </div>
                    </div>
                </main>
            </div>
            {showModal && (
                <BarangayProfilingTableModal
                    residents={filteredResidents}
                    filters={{
                        sex: sex || 'N/A',
                        ageRange: `${ageRange.from || 'N/A'} to ${ageRange.to || 'N/A'}`,
                        civilStatus: civilStatus || 'N/A',
                        nationality: nationality || 'N/A',
                        religion: religion || 'N/A',
                        voter: voter ? 'Voter' : '',
                        indigent: indigent ? 'Indigent' : '',
                        soloParent: soloParent ? 'Solo Parent' : '',
                        fourps: fourps ? '4Ps' : '',
                        pwd: pwd ? 'PWD' : '',
                    }}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
};

export default BarangayProfiling;
