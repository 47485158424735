import axios from 'axios';
import React, { useEffect, useState } from 'react';

const DashboardCards = () => {
    const [stats, setStats] = useState({
        totalPopulation: 0,
        totalVoters: 0,
        totalIndigent: 0,
        totalPWDs: 0,
        totalSeniorCitizens: 0,
        totalSoloParents: 0,
        total4Ps: 0,
    });

    const [pendingIncidentReports, setPendingIncidentReports] = useState(0);
    const [pendingDocumentRequests, setPendingDocumentRequests] = useState(0);
    const [pendingResidentVerifications, setPendingResidentVerifications] = useState(0);
    const [pendingProfileUpdates, setPendingProfileUpdates] = useState(0); // Placeholder if required later

    // Fetch the total population and other statistics
    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/barangays`);
                
                if (response.data.barangays && response.data.barangays.length > 0) {
                    const barangayStats = response.data.barangays[0];
                    setStats({
                        totalPopulation: barangayStats.population,
                        totalVoters: barangayStats.totalvoters,
                        totalIndigent: barangayStats.totalindigent,
                        totalPWDs: barangayStats.totalpwd,
                        totalSeniorCitizens: barangayStats.totalseniorcitizen,
                        totalSoloParents: barangayStats.totalsoloparent,
                        total4Ps: barangayStats.total4psbeneficiary,
                    });
                }
            } catch (error) {
                console.error('Error fetching dashboard statistics:', error);
            }
        };

        fetchStats();
    }, []);

    // Fetch pending incident reports, document requests, and resident verifications
    useEffect(() => {
        const fetchPendingCounts = async () => {
            try {
                // Fetch Pending Incident Reports
                const incidentResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/incident-reports`);
                const pendingIncidents = incidentResponse.data.filter(report => report.status === 'Pending');
                setPendingIncidentReports(pendingIncidents.length);

                // Fetch Pending Document Requests
                const documentResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/document-requests`);
                const pendingDocuments = documentResponse.data.requests.filter(request => request.status === 'Pending');
                setPendingDocumentRequests(pendingDocuments.length);

                // Fetch Pending Resident Verifications
                const residentResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents`);
    
                // Access the `residents` array inside the response
                const pendingResidents = residentResponse.data.residents.filter(resident => resident.accountStatus === 'Pending');
                setPendingResidentVerifications(pendingResidents.length);

                setPendingProfileUpdates(0); 
            } catch (error) {
                console.error('Error fetching pending counts:', error);
            }
        };

        fetchPendingCounts();
    }, []);

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Pending Incident Reports */}
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Pending Incident Reports</h2>
                <p className="text-2xl font-semibold">{pendingIncidentReports}</p>
            </div>

            {/* Pending Document Requests */}
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Pending Document Requests</h2>
                <p className="text-2xl font-semibold">{pendingDocumentRequests}</p>
            </div>

            {/* Pending Resident Verification */}
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Pending Resident Verification</h2>
                <p className="text-2xl font-semibold">{pendingResidentVerifications}</p>
            </div>

            {/* Pending Profile Update Requests (Placeholder) */}
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Pending Profile Update Requests</h2>
                <p className="text-2xl font-semibold">{pendingProfileUpdates}</p>
            </div>

            {/* Other Stats */}
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Total Population</h2>
                <p className="text-2xl font-semibold">{stats.totalPopulation}</p>
            </div>
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Total Voters</h2>
                <p className="text-2xl font-semibold">{stats.totalVoters}</p>
            </div>
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Total Indigent</h2>
                <p className="text-2xl font-semibold">{stats.totalIndigent}</p>
            </div>
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Total PWDs</h2>
                <p className="text-2xl font-semibold">{stats.totalPWDs}</p>
            </div>
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Total Senior Citizens</h2>
                <p className="text-2xl font-semibold">{stats.totalSeniorCitizens}</p>
            </div>
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Total Solo Parents</h2>
                <p className="text-2xl font-semibold">{stats.totalSoloParents}</p>
            </div>
            <div className="bg-white p-4 rounded shadow flex flex-col justify-between" style={{ height: '110px', width: '100%' }}>
                <h2 className="text-lg">Total 4Ps Beneficiaries</h2>
                <p className="text-2xl font-semibold">{stats.total4Ps}</p>
            </div>
        </div>
    );
};

export default DashboardCards;
