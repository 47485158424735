import React, { useState, useEffect } from 'react';
import { FaCamera } from 'react-icons/fa';
import SignUpDataPrivAgreement from '../assets/dataprivacyandtermsandconditions/SignUpDataPrivAgreement';

const PhotoUpload = ({ profilepic, handleClear, handleSubmit, setFormData, clearPhoto }) => {
    const [localProfilePic, setLocalProfilePic] = useState(profilepic || null); // Initialize with profilepic from parent
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showError, setShowError] = useState(true);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    // Sync localProfilePic with parent's profilepic
    useEffect(() => {
        setLocalProfilePic(profilepic);  // Update localProfilePic when parent profilepic changes
    }, [profilepic]);

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLocalProfilePic(file); // Set the selected file in local state
            setFormData(prevFormData => ({ ...prevFormData, profilepic: file })); // Update formData in parent
        }
    };

    const handleClearPhoto = () => {
        setLocalProfilePic(null); // Clear local state
        clearPhoto(); // Clear the photo in the parent
    };

    const handleFormSubmit = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        if (termsAccepted) {
            setShowError(false);
            handleSubmit(); 
        } else {
            setShowError(true); 
        }
    };

    return (
        <div className="flex flex-col items-center">
            <div className="mt-2" style={{ marginBottom: 490, marginTop: 5 }}>
                <div>
                    {/* Display the selected photo or a placeholder */}
                    {localProfilePic ? (
                        <img
                            src={URL.createObjectURL(localProfilePic)}
                            alt="Profile"
                            className="w-60 h-60 rounded-full bg-gray-300"
                        />
                    ) : (
                        <img
                            src={'path_to_placeholder_image'}
                            alt=""
                            className="w-60 h-60 rounded-full bg-gray-300"
                        />
                    )}
                    <label className="bg-[#1346AC] text-white w-full px-10 py-2 rounded-full mt-2 font-semibold flex items-center justify-center cursor-pointer">
                        <FaCamera className="mr-2" /> Add Photo
                        <input type="file" accept="image/*" onChange={handlePhotoChange} className="hidden" />
                    </label>
                </div>
            </div>
            <button
                type="button"
                onClick={handleClearPhoto}
                className="bg-gray-500 text-white w-full px-4 py-2 rounded-full font-semibold mb-2 mt-2"
            >
                Clear
            </button>
            <button
                type="button"
                onClick={(e) => handleFormSubmit(e)}
                className={`bg-[#1346AC] text-white w-full px-4 py-2 rounded-full font-semibold mb-2 mt-2 ${termsAccepted ? '' : 'opacity-50 cursor-not-allowed'}`}
                disabled={!termsAccepted}
            >
                Register    
            </button>
            <div className="mt-2 mb-2 flex items-center">
                <input
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={() => {
                        setTermsAccepted(!termsAccepted);
                        setShowError(!termsAccepted ? false : true); 
                    }}
                    className="mr-2"
                />
                <span className="text-md">
                    I agree to the{' '}
                    <span
                        className="text-blue-600 cursor-pointer underline"
                        onClick={() => setShowTermsModal(true)}
                    >
                        Terms and Conditions <br></br>
                    </span>{' '}
                    and{' '}
                    <span
                        className="text-blue-600 cursor-pointer underline"
                        onClick={() => setShowPrivacyModal(true)}
                    >
                        Data Privacy Agreement
                    </span>.
                </span>
            </div>
            {showError && (
                <p className="text-red-500 text-sm">
                    You must agree to the Terms and Conditions and Data Privacy Agreement
                </p>
            )}

            {/* Terms and Conditions Modal */}
            {showTermsModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-10 rounded-lg shadow-lg max-w-lg w-full">
                        <h2 className="text-2xl font-semibold mb-4 text-center">Terms and Conditions</h2>
                        <div className="overflow-y-auto max-h-96 mb-4">
                            <p><strong>1. Purpose of Registration</strong></p>
                            <p>iServe Barangay allows residents to access Barangay services, including filing complaints, requesting documents, and receiving updates.</p>
                            
                            <p><strong>2. Personal Information Collection</strong></p>
                            <p>We collect necessary information such as name, contact details, address, and valid IDs to verify your identity and provide our services.</p>
                            
                            <p><strong>3. Data Privacy</strong></p>
                            <p>Your personal information will be processed in line with the Data Privacy Act of 2012. We are committed to protecting your privacy and only sharing your data as required by law, with your consent, or as necessary to provide our services.</p>
                            
                            <p><strong>4. User Responsibilities</strong></p>
                            <p>You must provide accurate and current information. Misuse of the platform, such as submitting false information or engaging in abusive conduct, may result in restricted access.</p>
                            
                            <p><strong>5. Security and Data Retention</strong></p>
                            <p>We use security measures to protect your information and retain data only as long as necessary for service provision or as required by law.</p>
                            
                            <p><strong>6. Cookies and Tracking</strong></p>
                            <p>We may use cookies to enhance your experience on the platform. You can manage cookies through your browser settings.</p>
                            
                            <p><strong>7. Service Limitations</strong></p>
                            <p>While we strive to maintain platform reliability, iServe Barangay is not liable for any disruptions, errors, or delays in service.</p>
                            
                            <p>By completing the sign-up process, you confirm that you have read, understood, and agree to these Terms and Conditions.</p>
                        </div>
                        <button
                            onClick={() => setShowTermsModal(false)}
                            className="bg-[#1346AC] text-white w-full px-4 py-2 rounded-full font-semibold"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            {/* Data Privacy Agreement Modal */}
            {showPrivacyModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
                        <h2 className="text-2xl font-semibold mb-4 text-center">Data Privacy Agreement</h2>
                        <div className="overflow-y-auto max-h-96 mb-4">
                            <SignUpDataPrivAgreement />
                        </div>
                        <button
                            onClick={() => setShowPrivacyModal(false)}
                            className="bg-[#1346AC] text-white w-full px-4 py-2 rounded-full font-semibold"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PhotoUpload;
