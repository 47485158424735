import React, { useEffect, useState } from 'react'
import Navigation from '../../component/Navigation';
import Header from '../../component/Header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BarangayDirectory = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [adminData, setAdminData] = useState(null);
  const [barangayOfficials, setBarangayOfficials] = useState([]); // Store barangay officials data
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [pageRange, setPageRange] = useState([1, 2, 3, 4]);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch the barangay officials from the API
  useEffect(() => {
    const fetchBarangayOfficials = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/admins`);
        setBarangayOfficials(response.data.admins);
      } catch (error) {
        console.error('Error fetching barangay officials:', error);
      }
    };

    fetchBarangayOfficials();

    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
      const firstName = capitalizeWords(user.firstName);
      const lastName = capitalizeWords(user.lastName);
      const middleInitial = user.middleName ? capitalizeWords(user.middleName.charAt(0)) + '.' : '';
      setUserName(`${firstName} ${middleInitial} ${lastName}`);
      setAdminData(user);
      setUserRole(user.roleinBarangay);
    }
  }, []);

  

  // Handle search
  const filteredOfficials = barangayOfficials.filter(
    (official) =>
      official.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      official.lastName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLogout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/');
  };

  const getCurrentDate = () => {
      const date = new Date();
      return date.toLocaleDateString('en-US', {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
      });
  };
 
  // Pagination Logic
  const totalPages = Math.ceil(filteredOfficials.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEntries = filteredOfficials.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (pageRange[3] < totalPages) {
        setPageRange(pageRange.map(page => page + 1));
      }
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (pageRange[0] > 1) {
        setPageRange(pageRange.map(page => page - 1));
      }
    }
  };

  const handlePageChange = (pageNum) => {
    setCurrentPage(pageNum);
    const newRangeStart = Math.max(1, pageNum - 1);
    const newRangeEnd = Math.min(totalPages, newRangeStart + 3);
    setPageRange(Array.from({ length: newRangeEnd - newRangeStart + 1 }, (_, i) => newRangeStart + i));
  };



  return (
      <div className="flex flex-col min-h-screen">
          <Header userName={userName} userRole={userRole} handleLogout={handleLogout} getCurrentDate={getCurrentDate} profilePic={adminData?.profilepic}/>
          <div className="flex flex-1">
              <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
              <main className="flex-1 p-8 bg-gray-100">
                  <h1 className="text-4xl font-bold mb-5 text-gray-700">Barangay Officials Directory</h1>
                  <div className="flex justify-around mb-1">
                      <div className="bg-white p-6 rounded-lg shadow w-full">
                          <div className='flex-col justify-between items-center mb-4'>
                          <div className='flex justify-between items-center text-center mb-2'>
                          <h2 className="text-2xl font-semibold ">List of Barangay Officials</h2>
                          <button
                              className="bg-[#1346AC] text-white px-8 py-2 rounded-full font-semibold"
                              onClick={() => navigate('/Add-Barangay-Official')}
                          >
                              Add Barangay Official
                          </button>
                          </div>
                          <div className='flex justify-between'>
                          <div className="flex space-x-4 items-center">
                              <div className="w-48">
                                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Position</label>
                                  <select
                                      id="date"
                                      name="date"
          
                                      className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                  >
                                      <option>Captain</option>
                                      <option>Secretary</option>
                                      <option>Kagawad</option>
                                  </select>
                                </div>
                                  <button className="text-[#1346AC] hover:text-blue-500 cursor-pointer font-semibold mt-4" >
                                      Reset Filters
                                  </button>
                              </div>
                              <div className="flex flex-col items-end space-y-2">
                              <input
                                  type="text"
                                  placeholder="Search requestor name"
                                  className="w-80 px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500"
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              <div className="flex items-center space-x-1">
                                <label htmlFor="sortBy" className="text-md font-medium text-gray-700 whitespace-nowrap">
                                  Sort by
                                </label>
                                <div className="relative inline-flex items-center">
                                  <select
                                    id="sortBy"
                                    name="sortBy"
                                    className="block appearance-none text-base text-[#1346AC] font-semibold hover:text-blue-500 focus:outline-none focus:ring-0" 
                                    
                                    
                                  >
                                    <option value="Date">Date</option>
                                    <option value="Reference No.">Reference No.</option>
                                    <option value="Requestor Name">Requestor Name</option>
                                    <option value="Document Type">Document Type</option>
                                    <option value="Purpose">Purpose</option>
                                    <option value="Status">Status</option>
                                  </select>
                                    <svg
                                      className="h-5 w-5 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 0 010-1.414z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                </div>
                              </div>
                              </div>
                          </div>
                          </div>
                          <div className="overflow-x-auto scrollbar-thin text-center">
                          <table className="min-w-full bg-white border border-gray-200 text-center">
                            <thead>
                              <tr>
                                <th className="py-2 border-b border-r border-gray-200">Official ID</th>
                                <th className="py-2 border-b border-r border-gray-200">Name</th>
                                <th className="py-2 border-b border-r border-gray-200">Position</th>
                                <th className="py-2 border-b border-r border-gray-200">Contact Number</th>
                                <th className="py-2 border-b border-gray-200">Address</th>
                                <th className="py-2 border-b border-gray-200">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredOfficials.length > 0 ? (
                                filteredOfficials.slice(indexOfFirstItem, indexOfLastItem).map((official) => (
                                  <tr key={official.adminID}>
                                    <td className="py-2 px-4 border-b border-r">{official.adminID}</td>
                                    <td className="py-2 px-4 border-b border-r">
                                      {official.firstName} {official.middleName && `${official.middleName.charAt(0)}.`} {official.lastName}
                                    </td>
                                    <td className="py-2 px-4 border-b border-r">{official.roleinBarangay}</td>
                                    <td className="py-2 px-4 border-b border-r">{official.contactNumber}</td>
                                    <td className="py-2 px-4 border-b border-r">
                                      {official.permanentAddress.street}, {official.permanentAddress.barangay}, {official.permanentAddress.city}
                                    </td>
                                    <td className="px-2 w-72 border-b border-r border-gray-300 text-center font-semibold actions-column">
                                            <div className="flex justify-center space-x-4 items-center">
                                                <div className="border-r border-gray-300 pr-4">
                                                    <button className="text-[#1346AC] hover:text-blue-500" onClick>VIEW</button>
                                                </div>
                                                <div className="border-r border-gray-300 pr-4">
                                                    <button className="text-[#1346AC] hover:text-yellow-500" onClick>EDIT</button>
                                                </div>
                                                <div className="pl-4 pr-2">
                                                    <button className="text-[#1346AC] hover:text-red-500" onClick>ARCHIVE</button>
                                                </div>
                                            </div>
                                        </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="6" className="py-4 text-center text-gray-500">
                                    No officials found.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                  </div>

            
                <div className="flex justify-between items-center mt-4 font-semibold">
                <div className="text-sm text-gray-600">
                  Showing {Math.min(indexOfLastItem, barangayOfficials.length)} of {barangayOfficials.length} entries
                </div>
                <div className="flex space-x-2 font-semibold">
                  <button onClick={handlePrevPage} disabled={currentPage === 1} className="px-2 py-1 bg-gray-200 rounded hover:bg-[#1A50BE]">Previous</button>
                  {pageRange.map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={`px-2 py-1 rounded ${page === currentPage ? 'bg-[#1A50BE] text-white' : 'bg-gray-200 hover:bg-[#1A50BE]'}`}
                    >
                      {page}
                    </button>
                  ))}
                  <button onClick={handleNextPage} disabled={currentPage === totalPages} className="px-2 py-1 bg-gray-200 rounded hover:bg-[#1A50BE]">Next</button>        
            </div>
            </div>
            </div>
          </div>
        </main>
      </div>
    </div>

  );
};

export default BarangayDirectory;
