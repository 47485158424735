import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCityData, get5DaysForecast } from '../../Store/Slices/WeatherSlice';
import humidity from '../../assets/icons/humidity.png';
import wind from '../../assets/icons/wind.png';
import Header from '../../component/Header';
import Navigation from '../../component/Navigation';
import IncidentReport from '../../component/IncidentReport';
import DocumentRequest from '../../component/DocumentRequest';
import Announcement from '../../component/Announcement';
import DashboardCards from '../../component/DashboardCards';

const Home = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [adminData, setAdminData] = useState(null);
    const [city, setCity] = useState('Cavite');
    const [unit, setUnit] = useState('metric');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCityData({ city, unit }));
        dispatch(get5DaysForecast({ city, unit }));
    }, [city, unit, dispatch]);

    const {
        citySearchLoading,
        citySearchData,
        forecastLoading,
        forecastData,
        forecastError,
    } = useSelector((state) => state.weather);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, '/');
            }
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? `${capitalizeWords(user.middleName.charAt(0))}.` : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setAdminData(user);
            setUserRole(user.roleinBarangay);
            
            // Log user role to console
            console.log('Current user roleinBarangay:', user.roleinBarangay);
        }
    }, []);
    

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    return (
        <div className="flex flex-col min-h-screen scrollbar-thick overflow-y-auto h-64">
            <Header userName={userName} userRole={userRole} handleLogout={handleLogout}  profilePic={adminData?.profilepic}/>
            <div className="flex flex-1">
                <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
                <main className="flex-1 p-8 bg-gray-100">
                    <h1 className="text-4xl font-bold mb-5">Dashboard</h1>
                    <div className="flex flex-wrap-mx-2">
                        <div className="w-full lg:w-1/2 px-2">

                            <div >
                                <Announcement/>
                            </div>
                            <div className="mt-5">
                                <IncidentReport />
                            </div>
                            <div className="mt-5">
                                <DocumentRequest />
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 px-2">
                            <DashboardCards/>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Home;
