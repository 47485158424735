import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import defaultProfile from '../../../assets/icons/default-profile.png';
import ResidentNav from '../../../component/Resident/ResidentNav';
import ResidentHeader from '../../../component/Resident/ResidentHeader';

const ResidentEmergencyHotlines = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [residentData, setResidentData] = useState(null);
    const [hotlines, setHotlines] = useState([]);

    // Fetch hotlines on component mount
    useEffect(() => {
        const fetchHotlines = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/hotlines`);
                setHotlines(response.data); // Store fetched hotlines in state
            } catch (error) {
                console.error('Error fetching hotlines:', error);
            }
        };

        fetchHotlines();
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, '/'); // Adjust path for correct URL
            }
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? `${capitalizeWords(user.middleName.charAt(0))}.` : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setResidentData(user);
            setUserRole(user.roleinHousehold);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    const openWhatsApp = (phoneNumber) => {
        const formattedNumber = phoneNumber.replace(/[^0-9]/g, ''); // Strip non-numeric characters
        window.open(`https://wa.me/${formattedNumber}`, '_blank');
    };

    return (
        <div className="flex flex-col min-h-screen scrollbar-thick overflow-y-auto h-64">
            <ResidentHeader 
                userName={userName} 
                userRole={userRole} 
                handleLogout={handleLogout} 
                profilePic={residentData?.profilepic} 
            />
            <div className="flex flex-1">
                <ResidentNav residentData={residentData} />
                <div className="flex-1 p-6">
                    <h2 className="text-2xl font-bold mb-4">Emergency Hotlines</h2>

                    {/* Grid for displaying hotlines */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                        {hotlines.length > 0 ? (
                            hotlines.map(hotline => (
                                <div key={hotline._id} className="bg-white shadow-xl rounded-lg overflow-hidden flex p-4 min-h-[200px]">
                                    {/* Image on the left, centered vertically */}
                                    <figure className="flex-shrink-0 flex items-center justify-center">
                                        <img 
                                            src={hotline.photo || defaultProfile} 
                                            alt={hotline.name} 
                                            className="h-24 w-24 object-contain rounded-full"
                                        />
                                    </figure>
                                    
                                    <div className="flex flex-col justify-between flex-grow ml-4">
                                        <div>
                                            <h4 className="text-lg font-bold">{hotline.name}</h4>
                                            <p className="text-lg font-bold">Contact No: {hotline.contactNo}</p>
                                        </div>
                                        
                                        <div className="flex justify-end mt-4">
                                            <button
                                                className="btn text-white bg-[#1346AC] hover:bg-blue-700"
                                                onClick={() => openWhatsApp(hotline.contactNo)}
                                            >
                                                Contact via WhatsApp
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No hotlines available at the moment.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResidentEmergencyHotlines;
