import React, { useEffect, useState } from 'react';
import Navigation from '../../component/Navigation';
import Header from '../../component/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ViewIncidentReportModal from '../../component/ViewIncidentReportModal';
import Swal from 'sweetalert2';

const IncidentReport = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [adminData, setAdminData] = useState(null);
    const [incidentReports, setIncidentReports] = useState([]);  // Renamed from documentRequests
    const [filters, setFilters] = useState({
        date: 'All',
        incidentType: 'All',
        status: 'All' 
    });
    const [filteredIncidents, setFilteredIncidents] = useState([]);  // Adjusted for incident filtering
    const [sortBy, setSortBy] = useState('Date'); 
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [selectedIncident, setSelectedIncident] = useState(null); 
    const [searchTerm, setSearchTerm] = useState('');
    const [isDenyModalOpen, setIsDenyModalOpen] = useState(false); // State for the deny modal
    const [denyRemarks, setDenyRemarks] = useState(''); // State for remarks

    const [pageRange, setPageRange] = useState([1, 2, 3, 4]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, '/');
            }
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? capitalizeWords(user.middleName.charAt(0)) + '.' : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setAdminData(user);
            setUserRole(user.roleinBarangay);
        }
        // Fetch the incident reports from the API
        fetchIncidentReports();
    }, []);
    
    const fetchIncidentReports = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/incident-reports`);
            setIncidentReports(response.data);
            const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setFilteredIncidents(sortedData);
        } catch (error) {
            console.error("Error fetching incident reports", error);
        }
    };

  // Handle verification with SweetAlert confirmation
  const handleVerify = async (incidentId) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to verify this complaint?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/incident-reports/${incidentId}`, { status: 'Verified' });
                Swal.fire('Verified!', 'The complaint has been verified.', 'success');
                fetchIncidentReports(); // Refresh data
                setIsModalOpen(false); // Close the modal
            } catch (error) {
                console.error("Error verifying incident report", error);
            }
        }
    });
};
    
   // Handle archiving with SweetAlert confirmation
   const handleArchive = async () => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to archive this complaint?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/incident-reports/${selectedIncident._id}`, { status: 'Archived' });
                Swal.fire('Archived!', 'The complaint has been archived.', 'success');
                setIsArchiveModalOpen(false);
                fetchIncidentReports(); // Refresh data
            } catch (error) {
                console.error("Error archiving incident report", error);
            }
        }
    });
};

    // Handle denial with SweetAlert confirmation
    const handleDenySubmit = async () => {
        if (!denyRemarks.trim()) {
            Swal.fire('Error', 'Please provide remarks before denying.', 'error');
            return;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to deny this complaint?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/incident-reports/${selectedIncident._id}`, {
                        status: 'Rejected',
                        remarks: denyRemarks
                    });
                    Swal.fire('Denied!', 'The complaint has been denied.', 'success');
                    setIsDenyModalOpen(false);
                    setDenyRemarks('');
                    fetchIncidentReports(); // Refresh data
                } catch (error) {
                    console.error("Error rejecting incident report", error);
                }
            }
        });
    };


    const openDenyModal = (incident) => {
        setSelectedIncident(incident);
        setIsDenyModalOpen(true);
    };

    const closeDenyModal = () => {
        setIsDenyModalOpen(false);
        setDenyRemarks('');
        setSelectedIncident(null);
    };

    const openArchiveModal = (incident) => {
        setSelectedIncident(incident);
        setIsArchiveModalOpen(true);
    };
    
    const closeArchiveModal = () => {
        setIsArchiveModalOpen(false);
        setSelectedIncident(null);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);  // Update search term
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
    
        if (name === 'date') {
            setFilters(prevFilters => ({
                ...prevFilters,
                date: value, // Set the selected date filter (All, Latest, Oldest)
            }));
        } else {
            setFilters(prevFilters => ({
                ...prevFilters,
                [name]: value, // Handle other filters (like incidentType)
            }));
        }
    };
    
    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    };
    
    const resetFilters = () => {
        setFilters({
            date: 'All',
            incidentType: 'All',
            status: 'All'
        });
        setSearchTerm('');
        const sortedData = incidentReports.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setFilteredIncidents(sortedData);
    };
    
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };
    
    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };
    
    const predefinedCategories = [
        'Vehicular Accident', 
        'Disturbance of Peace', 
        'Physical Altercation', 
        'Harrassment', 
        'Property Damage', 
        'Unsettled Debts', 
        'Petty Crimes'
    ];
    
    useEffect(() => {
        let filtered = [...incidentReports];
    
        // Filter by status
        if (filters.status === 'All') {
            filtered = filtered.filter(report => report.status !== 'Archived');  // Show all except "Archived"
        } else if (filters.status === 'Archived') {
            filtered = filtered.filter(report => report.status === 'Archived');  // Show only archived
        } else {
            filtered = filtered.filter(report => report.status === filters.status);  // Filter by selected status
        }
    
        // Filter by incident type
        if (filters.incidentType !== 'All') {
            if (filters.incidentType === 'Others') {
                // Show incidents that are not in predefined categories
                filtered = filtered.filter(report => !predefinedCategories.includes(report.typeofcomplaint));
            } else {
                // Show incidents that match the selected category
                filtered = filtered.filter(report => report.typeofcomplaint === filters.incidentType);
            }
        }
    
        // Filter by date
        if (filters.date === 'All') {
            filtered = filtered.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)); 
        } else if (filters.date === 'Latest') {
            filtered = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));  // Sort by latest
        } else if (filters.date === 'Oldest') {
            filtered = filtered.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));  // Sort by oldest
        }
    
        // Apply search filter
        if (searchTerm) {
            filtered = filtered.filter(report =>
                report.complainantname.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
    
        // Apply sorting based on the selected sortBy option
        if (sortBy === 'Reference No.') {
            filtered = filtered.sort((a, b) => (a.ReferenceNo || '').localeCompare(b.ReferenceNo || ''));
        } else if (sortBy === 'Complainant Name') {
            filtered = filtered.sort((a, b) => (a.complainantname || '').localeCompare(b.complainantname || ''));
        } else if (sortBy === 'Incident Type') {
            filtered = filtered.sort((a, b) => (a.typeofcomplaint || '').localeCompare(b.typeofcomplaint || ''));
        }
    
        setFilteredIncidents(filtered);  // Update the filtered incidents
        setCurrentPage(1);  // Reset pagination
    }, [filters, sortBy, searchTerm, incidentReports]);
    
    
    
    
    // Pagination logic
    const totalPages = Math.ceil(filteredIncidents.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentEntries = filteredIncidents.slice(indexOfFirstItem, indexOfLastItem);

    // Pagination handlers
    const handleNext = () => {
        if (pageRange[3] < totalPages) {
            const newPageRange = pageRange.map(num => num + 4);
            setPageRange(newPageRange);
            setCurrentPage(newPageRange[0]);
        }
    };

    const handlePrevious = () => {
        if (pageRange[0] > 1) {
            const newPageRange = pageRange.map(num => num - 4);
            setPageRange(newPageRange);
            setCurrentPage(newPageRange[0]);
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    
    const viewIncidentReport = (reportId) => {
        const selectedIncident = incidentReports.find((report) => report._id === reportId);  // Find the incident report
        setSelectedIncident(selectedIncident);  // Set the selected report
        setIsModalOpen(true);  // Show modal
    };
    

    return (
        <div className="flex flex-col min-h-screen">
            <Header userName={userName} userRole={userRole} handleLogout={handleLogout} getCurrentDate={getCurrentDate} profilePic={adminData?.profilepic}/>
            <div className="flex flex-1">
                <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
                <main className="flex-1 p-8 bg-gray-100">
                    <h1 className="text-4xl font-bold mb-5 text-gray-700">Complaint</h1>
                    <div className="flex justify-around mb-1">
                        <div className="bg-white p-6 rounded-lg shadow w-full">
                        <div className='flex-col justify-between items-center mb-4'>
                            <div className='flex justify-between items-center text-center mb-2'>
                            <h2 className="text-2xl font-semibold ">List of Complaints</h2>
                            <button
                                className="bg-[#1346AC] text-white px-6 py-2 rounded-full font-semibold min-w-12"
                                onClick={() => navigate('/Create-Incident-Report')} 
                            >
                                Add Complaint
                            </button>
                            </div>
                            <div className='flex justify-between'>
                            <div className="flex space-x-4">
                                <div className="w-48">
                                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                                <select
                                    id="date"
                                    name="date"
                                    value={filters.date}
                                    onChange={handleFilterChange}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                >
                                    <option>All</option>
                                    <option>Latest</option>
                                    <option>Oldest</option>
                                </select>
                                </div>
                                <div className="w-48">
                                <label htmlFor="incidentType" className="block text-sm font-medium text-gray-700">Type</label>
                                <select
                                    id="incidentType"
                                    name="incidentType"
                                    value={filters.incidentType}
                                    onChange={handleFilterChange}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                >
                                    <option value="All">All</option>
                                    <option value="Vehicular Accident">Vehicular Accident</option>
                                    <option value="Disturbance of Peace">Disturbance of Peace</option>
                                    <option value="Physical Altercation">Physical Altercation</option>
                                    <option value="Harrassment">Harrassment</option>
                                    <option value="Property Damage">Property Damage</option>
                                    <option value="Unsettled Debts">Unsettled Debts</option>
                                    <option value="Petty Crimes">Petty Crimes</option>
                                    <option value="Others">Others</option>
                                </select>
                                </div>
                                <div className="w-48">
                                <label htmlFor="incidentType" className="block text-sm font-medium text-gray-700">Status</label>
                                <select
                                    id="status"
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                >
                                    <option value="All">All</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Active">Active</option>
                                    <option value="Processing">Processing</option>
                                    <option value="Verified">Verified</option>
                                    <option value="Settled">Settled</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Archived">Archived</option>
                                </select>
                                </div>
                                <button className="text-[#1346AC] hover:text-blue-500 cursor-pointer font-semibold" onClick={resetFilters}>
                                Reset Filters
                                </button>
                            </div>
                            <div className="flex flex-col items-end space-y-2">
                                <input
                                    type="text"
                                    placeholder="Search complainant name"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    className="w-80 px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500"
                                />
                                <div className="flex items-center space-x-1">
                                <label htmlFor="sortBy" className="text-md font-medium text-gray-700 whitespace-nowrap">
                                    Sort by
                                </label>
                                <div className="relative inline-flex items-center">
                                    <select
                                    id="sortBy"
                                    name="sortBy"
                                    className="block appearance-none text-base text-[#1346AC] font-semibold hover:text-blue-500 focus:outline-none focus:ring-0"
                                    value={sortBy}
                                    onChange={handleSortChange}
                                    >
                                    <option value="Complainant Name">Complainant Name</option>
                                    <option value="Reference No.">Reference No.</option>
                                    </select>
                                    <svg
                                    className="h-5 w-5 text-gray-500"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    >
                                    <path
                                        fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                    </svg>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="overflow-x-auto scrollbar-thin text-center">
                            <table className="min-w-full bg-white border border-gray-200">
                            <thead>
                                <tr>
                                <th className="py-2 border-b border-r border-gray-200">Date Filed</th>
                                <th className="py-2 border-b border-r border-gray-200">Reference No.</th>
                                <th className="py-2 border-b border-r border-gray-200">Complaint Type</th>
                                <th className="py-2 border-b border-r border-gray-200">Description</th>
                                <th className="py-2 border-b border-r border-gray-200">Complainant Name</th>
                                <th className="py-2 border-b border-r border-gray-200">Status</th>
                                <th className="py-2 border-b border-gray-200">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentEntries.map((incident) => (
                                <tr key={incident._id} className="border-t border-gray-200">
                                    <td className="py-2 px-4 border-b border-r border-gray-200 text-center">
                                    {new Date(incident.created_at).toLocaleDateString()}
                                    </td>
                                    <td className="py-2 px-4 border-b border-r border-gray-200 text-center">
                                    {incident.ReferenceNo}
                                    </td>
                                    <td className="py-2 px-4 border-b border-r border-gray-200 text-center">
                                    {incident.typeofcomplaint}
                                    </td>
                                    <td className="py-2 px-4 border-b border-r border-gray-200 text-center truncate">
                                    {incident.incidentdescription}
                                    </td>
                                    <td className="py-2 px-4 border-b border-r border-gray-200 text-center">
                                    {`${incident.complainantname}`}
                                    </td>
                                    <td className="py-2 px-4 border-b border-r border-gray-200 text-center">
                                    <span className={`px-4 py-1 rounded-full font-semibold 
                                        ${incident.status === 'Pending' ? 'bg-[#FFEA00]' :
                                        incident.status === 'Active' ? 'bg-[#5C80FF]' :
                                        incident.status === 'Processing' ? 'bg-[#FF8C00]' :
                                        incident.status === 'Verified' ? 'bg-[#00BFFF]' :
                                        incident.status === 'Settled' ? 'bg-[#4D9669]' :
                                        incident.status === 'Rejected' ? 'bg-red-600' :
                                        incident.status === 'Archived' ? 'bg-red-700 text-white' :
                                        'bg-red-200'}`}>
                                        {incident.status}
                                    </span>
                                    </td>
                                    <td className="py-2 px-4 border-b border-gray-200 space-x-2 text-center font-semibold">
                                    <div className="flex justify-center space-x-4 items-center">
                                        <div className="border-r border-gray-300 pr-4">
                                        <button className="text-lg text-[#1346AC] hover:text-blue-500"
                                        onClick={() => viewIncidentReport(incident._id)}>
                                                View
                                        </button>
                                        </div>
                                        <div className="border-r border-gray-300 pr-4">
                                        <button
                                            className="text-[#1346AC] hover:text-yellow-500"
                                            onClick={() => openDenyModal(incident)}
                                        >
                                            DENY
                                        </button>
                                        </div>
                                        <div className="pl-4 pr-2">
                                        <button
                                            className="text-[#1346AC] hover:text-red-500"
                                            onClick={() => openArchiveModal(incident)}
                                        >
                                            ARCHIVE
                                        </button>
                                        </div>
                                    </div>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>

                        <div className="flex justify-between items-center mt-4 font-semibold">
                            <div className="text-sm text-gray-600">
                                Showing {Math.min(indexOfLastItem, filteredIncidents.length)} of {filteredIncidents.length} entries
                            </div>
                            <div className="mt-4 flex items-center">
                                <button
                                    onClick={handlePrevious}
                                    className="px-3 py-1 mr-2 rounded bg-gray-200 hover:bg-[#1346AC] hover:text-white"
                                    disabled={pageRange[0] === 1}
                                >
                                    Previous
                                </button>
                                {pageRange.map((pageNumber) => (
                                    <button
                                        key={pageNumber}
                                        onClick={() => paginate(pageNumber)}
                                        className={`px-3 py-1 mr-2 rounded ${currentPage === pageNumber ? 'bg-[#1346AC] text-white' : 'bg-gray-200 hover:bg-[#1346AC] hover:text-white'}`}
                                        disabled={pageNumber > totalPages}
                                    >
                                        {pageNumber}
                                    </button>
                                ))}
                                <button
                                    onClick={handleNext}
                                    className="px-3 py-1 rounded bg-gray-200 hover:bg-[#1346AC] hover:text-white"
                                    disabled={pageRange[3] >= totalPages}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </main>
            {isArchiveModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg p-8 shadow-lg max-w-sm w-full">
                        <h2 className="text-lg font-semibold mb-4">Are you sure you want to Archive this Complaint?</h2>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="bg-[#1346AC] text-white px-4 py-2 rounded hover:bg-blue-700"
                                onClick={handleArchive}
                            >
                                Yes
                            </button>
                            <button
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                                onClick={closeArchiveModal}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

                {isDenyModalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white rounded-lg p-8 shadow-lg max-w-md w-full">
                                <h2 className="text-lg font-semibold mb-4">Provide Remarks for Rejection</h2>
                                <textarea
                                    className="w-full h-24 p-2 border border-gray-300 rounded-md"
                                    value={denyRemarks}
                                    onChange={(e) => setDenyRemarks(e.target.value)}
                                    placeholder="Enter your remarks here..."
                                />
                                <div className="flex justify-end space-x-4 mt-4">
                                    <button
                                        className="bg-[#1346AC] hover:bg-blue-700 text-white px-4 py-2 rounded"
                                        onClick={handleDenySubmit}
                                    >
                                        Send
                                    </button>
                                    <button
                                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                                        onClick={closeDenyModal}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
            {isModalOpen && selectedIncident && (
            <ViewIncidentReportModal
                incidentReport={selectedIncident}
                onClose={() => setIsModalOpen(false)}
                onVerify={handleVerify}
            />
        )}
      </div>

    );
};

export default IncidentReport;