import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {  FaUser, FaUsers,  FaInfoCircle, FaCog, FaCopy, FaTimes } from 'react-icons/fa';
import {  MdMap, MdDashboard, MdChatBubble} from "react-icons/md";
import { BiSolidMegaphone } from "react-icons/bi";
import { FaBars, FaFilePen, FaIdBadge, FaSquarePhone } from "react-icons/fa6";

const Navigation = ({ adminData, getCurrentDate }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };
    return (
<>
            <button
                onClick={toggleDrawer}
                className="lg:hidden fixed top-4 left-4 z-50 bg-[#1346AC] p-2 rounded text-white">
                <FaBars className="w-6 h-6" />
            </button>

            {/* Main Navigation, visible on large screens */}
            <nav className="bg-gray-100 text-black flex-col justify-between p-4 shadow hidden lg:flex overflow-y-auto scrollbar-hidden">
                {/* Original navigation links */}
                <div>
                    <h2 className="mb-4 ml-2 mt-2 font-bold text-xl">GENERAL</h2>
                    <ul className="space-y-2">
                    <li>
                        <Link to={{ pathname: "/home", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <MdDashboard className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={{ pathname: "/BarangayProfiling", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <FaUser className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Barangay Profiling</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={{ pathname: "/ResidentManagement", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <FaUsers className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg leading-tight">Resident and<br />Household Management</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={{ pathname: "/Announcement", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <BiSolidMegaphone className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Announcements</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={{ pathname: "/Messages", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <MdChatBubble className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Messages</span>
                        </Link>
                    </li>
                    <li>
                        <a href="/Incident-Report" className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <FaFilePen className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Complaint</span>
                        </a>
                    </li>
                    <li>
                        <Link to={{ pathname: "/Document-Request", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <FaCopy className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Document Request</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={{ pathname: "/Barangay-Directory", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <FaIdBadge className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Barangay Directory</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={{ pathname: "/Barangay-Information", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <FaInfoCircle className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Barangay Information</span>
                        </Link>
                    </li>
                </ul>

                <h2 className="mt-2 ml-2 mb-1 font-bold text-xl">Emergency Services</h2>
                <ul className="space-y-2">
                <li>
                <Link
                    to={{ pathname: "/EvacuationMap", state: { adminData } }}
                    className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded"
                >
                    <MdMap className="w-6 h-6" />
                    <span className="ml-4 font-semibold text-lg">Evacuation Map</span>
                </Link>
                </li>
                <li>
                <Link
                    to={{ pathname: "/Emergency-Hotlines", state: { adminData } }}
                    className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded"
                >
                    <FaSquarePhone  className="w-6 h-6" />
                    <span className="ml-4 font-semibold text-lg">Emergency Hotlines</span>
                </Link>
                    </li>
                </ul>
                <div className="text-center mt-2">
                    <p className="text-black font-bold">{getCurrentDate()}</p>
                </div>
            </div>
        </nav>
        <div
                className={`fixed top-0 left-0 w-64 h-full bg-gray-100 shadow-xl transform ${
                    isDrawerOpen ? 'translate-x-0' : '-translate-x-full'
                } transition-transform duration-300 ease-in-out z-40 lg:hidden`}>
                <button
                    onClick={toggleDrawer}
                    className="absolute top-4 right-4 bg-red-600 p-2 rounded text-white">
                    <FaTimes className="w-6 h-6" />
                </button>

                <div className="p-4">
                    <h2 className="mb-4 ml-2 mt-2 font-bold text-xl">GENERAL</h2>
                    <ul className="space-y-2">
                        <li>
                            <Link to="/home" className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                                <MdDashboard className="w-6 h-6" />
                                <span className="ml-4 font-semibold text-lg">Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/BarangayProfiling" className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                                <FaUser className="w-6 h-6" />
                                <span className="ml-4 font-semibold text-lg">Barangay Profiling</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ResidentManagement" className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                                <FaUsers className="w-6 h-6" />
                                <span className="ml-4 font-semibold text-lg leading-tight">
                                    Resident and Household Management
                                </span>
                            </Link>
                        </li>
                        <li>
                        <Link to={{ pathname: "/Announcement", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <BiSolidMegaphone className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Announcements</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={{ pathname: "/Messages", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <MdChatBubble className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Messages</span>
                        </Link>
                    </li>
                    <li>
                        <a href="/Incident-Report" className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <FaFilePen className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Incident Report</span>
                        </a>
                    </li>
                    <li>
                        <Link to={{ pathname: "/Document-Request", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <FaCopy className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Document Request</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={{ pathname: "/Barangay-Directory", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <FaIdBadge className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Barangay Directory</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={{ pathname: "/Barangay-Information", state: { adminData } }} className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded">
                            <FaInfoCircle className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Barangay Information</span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={{ pathname: "/EvacuationMap", state: { adminData } }}
                            className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded"
                        >
                            <MdMap className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Evacuation Map</span>
                        </Link>
                        </li>
                        <li>
                        <Link
                            to={{ pathname: "/Emergency-Hotlines", state: { adminData } }}
                            className="flex items-center p-4 text-black hover:bg-[#1346AC] hover:text-white hover:rounded"
                        >
                            <FaSquarePhone  className="w-6 h-6" />
                            <span className="ml-4 font-semibold text-lg">Emergency Hotlines</span>
                        </Link>
                    </li>
                        </ul>
                </div>
            </div>
        </>
    );
};

export default Navigation;
