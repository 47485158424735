import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';

const ChangePasswordModal = ({ isOpen, onClose }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReEnterPassword, setShowReEnterPassword] = useState(false);

  // List of weak passwords
  const weakPasswords = ['123456', 'password', '123456789', '12345678', 'qwerty', 'abc123', '111111'];

  const handleChangePassword = async (e) => {
    e.preventDefault();

    // Validate new password and re-entered password match
    if (newPassword !== reEnterPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'New password and re-entered password do not match',
      });
      return;
    }

    // Validate for weak passwords
    if (weakPasswords.includes(newPassword.toLowerCase())) {
      Swal.fire({
        icon: 'warning',
        title: 'Weak Password',
        text: 'Please choose a stronger password.',
      });
      return;
    }

    // Password length validation
    if (newPassword.length < 6) {
      Swal.fire({
        icon: 'warning',
        title: 'Weak Password',
        text: 'Password must be at least 6 characters long.',
      });
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_API_KEY}/api/admin/change-password`,
        {
          oldPassword,
          newPassword
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Password Changed',
          text: 'Your password has been changed successfully.',
        });
        setOldPassword('');
        setNewPassword('');
        setReEnterPassword('');
        onClose(); // Close modal on success
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message || 'Failed to change password',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'An error occurred',
      });
    }
  };

  if (!isOpen) return null; 

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Change Password</h2>
        <form onSubmit={handleChangePassword} className="space-y-4">

          <div>
            <label className="block text-lg font-medium text-gray-700 mb-2">Old Password</label>
            <div className="flex mb-4 space-x-2 relative">
              <input
                type={showOldPassword ? 'text' : 'password'}
                name="oldPassword"
                placeholder="Enter your old password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <FontAwesomeIcon
                icon={showOldPassword ? faEye : faEyeSlash}
                onClick={() => setShowOldPassword(!showOldPassword)}
                className="absolute inset-y-0 right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-700 mb-2">New Password</label>
            <div className="flex mb-4 space-x-2 relative">
              <input
                type={showNewPassword ? 'text' : 'password'}
                name="newPassword"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <FontAwesomeIcon
                icon={showNewPassword ? faEye : faEyeSlash}
                onClick={() => setShowNewPassword(!showNewPassword)}
                className="absolute inset-y-0 right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-700 mb-2">Re-enter Password</label>
            <div className="flex mb-4 space-x-2 relative">
              <input
                type={showReEnterPassword ? 'text' : 'password'}
                name="reEnterPassword"
                placeholder="Re-enter your new password"
                value={reEnterPassword}
                onChange={(e) => setReEnterPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <FontAwesomeIcon
                icon={showReEnterPassword ? faEye : faEyeSlash}
                onClick={() => setShowReEnterPassword(!showReEnterPassword)}
                className="absolute inset-y-0 right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              />
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#1346AC] text-white px-6 py-2 rounded-lg hover:bg-blue-700"
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
