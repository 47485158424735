import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const ViewIncidentReportModal = ({ onClose, incidentReport, onVerify }) => {
    // Format the date
    const formattedDate = new Date(incidentReport.dateAndTimeofIncident).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const handleVerifyClick = () => {
        if (incidentReport.status === 'Verified') {
            Swal.fire({
                icon: 'info',
                title: 'Complaint Already Verified',
                text: 'This complaint has already been verified.',
                confirmButtonColor: '#1346AC',
            });
        } else {
            onVerify(incidentReport._id); // Call the verify function passed in the props
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto" onClick={onClose}>
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/4 md:w-2/2 max-h-[80vh] scrollbar-thin overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                <h2 className="text-xl font-semibold mb-4">Complaint</h2>
                
                <div className="text-left mb-4">
                    <p className="font-semibold">Reference No.</p>
                    <p>{incidentReport.ReferenceNo}</p>
                </div>

                <div className="text-left mb-4">
                    <p className="font-semibold">Date of Incident</p>
                    <p>{formattedDate}</p>
                </div>

                {/* Complainant Names */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Complainant(s)</p>
                    <ul className="list-decimal list-inside">
                        {incidentReport.complainantname.map((name, index) => (
                            <li key={index}>{name}</li>
                        ))}
                    </ul>
                </div>

                {/* Respondent Names */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Respondent(s)</p>
                    <ul className="list-decimal list-inside">
                        {incidentReport.respondentname.map((name, index) => (
                            <li key={index}>{name}</li>
                        ))}
                    </ul>
                </div>

                {/* Type of Complaint */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Type of Complaint</p>
                    <p>{incidentReport.typeofcomplaint}</p>
                </div>

                {/* Incident Description */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Incident Description</p>
                    <p>{incidentReport.incidentdescription}</p>
                </div>

                {/* Relief to Be Granted */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Relief to Be Granted</p>
                    <p>{incidentReport.relieftobegranted}</p>
                </div>

                {/* Attachments */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Attachments</p>
                    <div className="flex flex-col space-y-2">
                        {incidentReport.Attachment && incidentReport.Attachment.length > 0 ? (
                            incidentReport.Attachment.map((attachment, index) => (
                                <div key={index} className="relative flex items-center justify-between p-2 border rounded-lg shadow-sm bg-gray-50">
                                    <div className="flex items-center">
                                        <div className="w-10 h-10 bg-[#1346AC] text-white flex items-center justify-center rounded-full mr-3">
                                            <i className="fas fa-file-alt"></i>
                                        </div>
                                        <a href={attachment.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 font-semibold hover:underline truncate">
                                            {attachment.originalname}
                                        </a>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No attachments available</p>
                        )}
                    </div>
                </div>

                {/* Remarks Section */}
                {incidentReport.remarks && (
                    <div className="text-left mb-4">
                        <p className="font-semibold">Remarks</p>
                        <p>{incidentReport.remarks}</p>
                    </div>
                )}

                {/* Buttons */}
                <div className="flex flex-col space-y-2 text-center mt-6">
                    <button
                        onClick={handleVerifyClick}
                        className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold hover:bg-blue-700"
                    >
                        Verify
                    </button>
                    <button onClick={onClose} className="bg-white text-[#1346AC] border border-[#1346AC] px-4 py-2 rounded-full font-semibold mt-2">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ViewIncidentReportModal;
