import React from 'react';

const IncidentReportDataPrivAgreement = ({ closeModal }) => {
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/4 md:w-2/2 max-h-[80vh] scrollbar-thin overflow-y-auto">
          <h2 className="text-2xl font-semibold mb-4 text-center">Data Privacy Agreement</h2>
          <div className="overflow-y-auto max-h-96 mb-4">
            <p className="m-2 text-start">
              I hereby certify that the above information are true and correct to the best of my knowledge. I understand that for the Barangay to carry out its mandate pursuant to Section 394 (d)(6) of the Local Government Code of 1991, they must necessarily process my personal information for easy identification of inhabitants, as a tool in planning, and as an updated reference in the number of inhabitants of the Barangay.
            </p>
            <p className="m-2 text-start">
              Therefore, I grant my consent and recognize the authority of the Barangay to process my personal information, subject to the provision of the Philippine Data Privacy Act of 2012.
            </p>
          </div>
          <button
            onClick={closeModal}
            className="bg-[#1346AC] text-white w-full px-4 py-2 rounded-full font-semibold hover:bg-blue-700"
          >
            Close
          </button>
        </div>
      </div>
    );
};

export default IncidentReportDataPrivAgreement;
