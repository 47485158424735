import React from 'react';

const IncidentReportTermsandConditions = ({ closeModal }) => {
    return (
        <div className="overflow-y-auto max-h-96 mb-4 p-4">
            <h2 className="text-2xl font-semibold mb-4 text-center">Terms and Conditions</h2>
                <div className="overflow-y-auto max-h-96 mb-4">
                    <p><strong>Purpose:</strong> iServe Barangay allows residents to file complaints for prompt Barangay action.</p>
                    <p><strong>Accuracy:</strong> You must provide accurate and truthful information in your complaint.</p>
                    <p><strong>Respectful Conduct:</strong> Use respectful language; abusive submissions will not be accepted.</p>
                    <p><strong>Privacy:</strong> Your personal information is protected under the Data Privacy Act of 2012.</p>
                    <p><strong>Platform Limitations:</strong> iServe Barangay is not liable for delays or technical issues.</p>
                </div>
                <button
                    onClick={closeModal}
                    className="mt-2 bg-[#1346AC] text-white w-full px-4 py-2 rounded-full font-semibold hover:bg-blue-700"
                >
                    Close
                </button>
            </div>
    );
};

export default IncidentReportTermsandConditions;
