import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResidentAnnouncement from '../../../component/Resident/ResidentAnnouncement';
import ResidentHeader from '../../../component/Resident/ResidentHeader';
import HomepageCards from '../../../component/Resident/HomepageCards';
import Swal from 'sweetalert2';
import axios from 'axios';

const ResidentHome = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [residentData, setResidentData] = useState(null);
    
    // States to control if alerts are shown
    const [showDocumentAlert, setShowDocumentAlert] = useState(false);
    const [showIncidentAlert, setShowIncidentAlert] = useState(false);
    const [approvedRequestsCount, setApprovedRequestsCount] = useState(0);
    const [verifiedReportsCount, setVerifiedReportsCount] = useState(0);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, '/'); // Adjust path for correct URL
            }
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? `${capitalizeWords(user.middleName.charAt(0))}.` : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setResidentData(user);
            setUserRole(user.roleinHousehold);

            // Fetch both document requests and incident reports concurrently
            fetchData(user._id);
        }
    }, []);

    // Function to fetch both document requests and incident reports concurrently
    const fetchData = async (userId) => {
        try {
            const [documentRequestsResponse, incidentReportsResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/document-requests/history/${userId}`),
                axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/incident-reports/history/${userId}`)
            ]);

            const documentRequests = documentRequestsResponse.data;
            const incidentReports = incidentReportsResponse.data;

            // Handle approved document requests and verified incident reports
            const approvedRequests = documentRequests.filter(request => request.status === 'Approved');
            const verifiedReports = incidentReports.filter(report => report.status === 'Verified');

            // Set the counts
            setApprovedRequestsCount(approvedRequests.length);
            setVerifiedReportsCount(verifiedReports.length);

            // Show alerts only if counts are greater than zero
            if (verifiedReports.length > 0) {
                setShowIncidentAlert(true);
            }
            if (approvedRequests.length > 0) {
                setShowDocumentAlert(true);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Show alerts on the screen after data is fetched
    useEffect(() => {
        if (showIncidentAlert) {
            Swal.fire({
                icon: 'success',
                title: 'Complaint Verified',
                text: `You have ${verifiedReportsCount} verified complaint(s). You may now visit  the barangay to further process your complaint(s).`,
                confirmButtonColor: '#1346AC'
            }).then(() => {
                setShowIncidentAlert(false); // Disable the incident alert after showing
                if (showDocumentAlert) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Document Approved',
                        text: `You have ${approvedRequestsCount} approved document request(s). You may now visit the barangay to collect your document(s).`,
                        confirmButtonColor: '#1346AC'
                    });
                    setShowDocumentAlert(false); // Disable the document alert after showing
                }
            });
        } else if (showDocumentAlert) {
            // If there was no incident alert, show the document request alert
            Swal.fire({
                icon: 'success',
                title: 'Document Approved',
                text: `You have ${approvedRequestsCount} approved document request(s). You can go to the barangay to get your document(s).`,
                confirmButtonColor: '#1346AC'
            });
            setShowDocumentAlert(false); // Disable the document alert after showing
        }
    }, [showIncidentAlert, showDocumentAlert, approvedRequestsCount, verifiedReportsCount]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            {/* Fixed Header */}
            <div className="fixed top-0 w-full z-50">
                <ResidentHeader 
                    userName={userName} 
                    userRole={userRole} 
                    handleLogout={handleLogout} 
                    profilePic={residentData?.profilepic} 
                />
            </div>

            {/* Main Content */}
            <div className="flex-1 flex flex-col justify-center pt-20 md:pt-24">
                <h1 className="text-4xl font-bold px-8">Home</h1>
                <main className="flex flex-col md:flex-row p-8 w-full space-y-4 md:space-y-0 md:space-x-4 md:h-full">
  
                    {/* Cards Section on the left */}
                    <div className="w-full md:w-1/2 flex-grow md:h-auto">
                        <HomepageCards residentData={residentData} />
                    </div>

                    {/* Announcement Section on the right */}
                    <div className="w-full md:w-1/2 flex-grow md:h-auto">
                        <ResidentAnnouncement />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ResidentHome;
