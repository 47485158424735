import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for fetching data
import Header from '../../component/Header';
import Navigation from '../../component/Navigation';
import VicinityMap from '../../assets/images/vicinity.png';
import Swal from 'sweetalert2';

const BarangayInformation = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [adminData, setAdminData] = useState(null);
    const [barangay, setBarangay] = useState(null); // State to hold barangay data
    const [isEditing, setIsEditing] = useState(false); // State for edit mode
    const [formData, setFormData] = useState({}); // Form state for editing
    const [logoFile, setLogoFile] = useState(null); // State to hold selected logo file
    const [logoPreview, setLogoPreview] = useState(''); // State to preview the new logo

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? capitalizeWords(user.middleName.charAt(0)) + '.' : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setAdminData(user);
            setUserRole(user.roleinBarangay);
        }
    }, []);

    useEffect(() => {
        const fetchBarangay = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/barangay`); // Fetch the only barangay
                setBarangay(response.data.barangay);
                setFormData(response.data.barangay); // Initialize form data with barangay data
            } catch (error) {
                console.error('Error fetching barangay data:', error);
            }
        };

        fetchBarangay(); // Fetch barangay data when the component mounts
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setFormData(barangay); // Reset form data to initial barangay data
        setLogoFile(null); // Reset logo file
        setLogoPreview(''); // Reset logo preview
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogoFile(file);
            setLogoPreview(URL.createObjectURL(file)); // Preview the selected logo
        }
    };

    const handleSaveClick = async () => {
        const formDataToSend = new FormData();
    
        // Append other editable fields to the FormData
        for (const key in formData) {
            if (key !== 'population') {  // Exclude population field from being updated
                if (formData[key] === '' || formData[key] === 'null') {
                    formDataToSend.append(key, null);  // Ensure null values are sent correctly
                } else if (Array.isArray(formData[key])) {
                    // Filter out any empty values from the kagawad array
                    formDataToSend.append(key, JSON.stringify(formData[key].filter(val => val)));
                } else {
                    formDataToSend.append(key, formData[key]);
                }
            }
        }
    
        // Append the logo file if selected
        if (logoFile) {
            formDataToSend.append('logo', logoFile);
        }
    
        // Show SweetAlert loading indicator
        Swal.fire({
            title: 'Uploading...',
            text: 'Please wait while the data is being uploaded.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();  // Show loading spinner
            },
        });
    
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/barangay/${barangay._id}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',  // Ensure correct headers for file upload
                },
            });
    
            setBarangay(formData);  // Update state with the new data
            setIsEditing(false);  // Exit edit mode
    
            // Close the loading alert and show a success message
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Information updated successfully',
                confirmButtonColor: '#3085d6',
            });
        } catch (error) {
            console.error('Error saving barangay information:', error);
    
            // Close the loading alert and show an error message
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to update information. Please try again.',
                confirmButtonColor: '#d33',
            });
        }
    };
    
    return (
        <div className="flex flex-col min-h-screen">
            <Header userName={userName} userRole={userRole} handleLogout={handleLogout} />
            <div className="flex flex-1">
                <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
                <main className="flex-1 p-6 bg-gray-100">
                    <div className="flex items-center mb-7">
                        <h1 className="text-4xl font-bold">Barangay Information</h1>
                    </div>
                    <div className="flex justify-center items-center space-x-4">
                        {/* Barangay Information Card */}
                        <div className="bg-white flex flex-col p-6 rounded-lg shadow-md w-1/4 space-y-5">
                            <div className='flex w-full items-center justify-center'>
                                {logoPreview ? (
                                    <img src={logoPreview} alt="New Logo Preview" className="w-64 h-64 object-cover mb-4" />
                                ) : (
                                    <img src={barangay?.logo} alt="Barangay Logo" className="w-64 h-64 object-cover mb-4" />
                                )}
                            </div>
                            {isEditing && (
                                <div className="flex items-center justify-center">
                                    <label
                                        htmlFor="logo-upload"
                                        className="bg-[#1346AC] text-white py-2 px-4 rounded-lg cursor-pointer hover:bg-blue-700 transition"
                                    >
                                        Choose New Logo
                                    </label>
                                    {/* The hidden file input */}
                                    <input
                                        id="logo-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleLogoChange}
                                        className="hidden"
                                    />
                                </div>
                            )}
                            <div>
                                <h2 className="font-bold text-lg">Barangay</h2>
                                {isEditing ? (
                                    <input
                                        type="text"
                                        name="barangayName"
                                        value={formData.barangayName}
                                        onChange={handleInputChange}
                                        className="border rounded w-full p-2"
                                    />
                                ) : (
                                    <p>{barangay?.barangayName || 'Loading...'}</p>
                                )}
                            </div>
                            <div>
                                <h2 className="font-bold text-lg">Municipality</h2>
                                {isEditing ? (
                                    <input
                                        type="text"
                                        name="municipality"
                                        value={formData.municipality}
                                        onChange={handleInputChange}
                                        className="border rounded w-full p-2"
                                    />
                                ) : (
                                    <p>{barangay?.municipality || 'Loading...'}</p>
                                )}
                            </div>
                            <div>
                                <h2 className="font-bold text-lg">Province</h2>
                                {isEditing ? (
                                    <input
                                        type="text"
                                        name="province"
                                        value={formData.province}
                                        onChange={handleInputChange}
                                        className="border rounded w-full p-2"
                                    />
                                ) : (
                                    <p>{barangay?.province || 'Loading...'}</p>
                                )}
                            </div>
                            <div>
                                <h2 className="font-bold text-lg">Email</h2>
                                {isEditing ? (
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="border rounded w-full p-2"
                                    />
                                ) : (
                                    <p>{barangay?.email || 'Loading...'}</p>
                                )}
                            </div>
                            <div>
                                <h2 className="font-bold text-lg">Phone</h2>
                                {isEditing ? (
                                    <input
                                        type="text"
                                        name="contactnumber"
                                        value={formData.contactnumber}
                                        onChange={handleInputChange}
                                        className="border rounded w-full p-2"
                                    />
                                ) : (
                                    <p>{barangay?.contactnumber || 'Loading...'}</p>
                                )}
                            </div>
                        </div>

                        {/* Additional Information Section */}
                        <div className="bg-white flex flex-col p-6 rounded-lg shadow-md w-3/4">
                            <h2 className="text-2xl font-semibold mb-6">Barangay Information</h2>

                            {/* History Section */}
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold">History</h3>
                                {isEditing ? (
                                    <textarea
                                        name="history"
                                        value={formData.history}
                                        onChange={handleInputChange}
                                        className="border rounded w-full p-2"
                                        rows={3}
                                    />
                                ) : (
                                    <p>{barangay?.history || 'Loading...'}</p>
                                )}
                            </div>

                            {/* Location Section */}
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold">Location</h3>
                                {isEditing ? (
                                    <textarea
                                        name="location"
                                        value={formData.location}
                                        onChange={handleInputChange}
                                        className="border rounded w-full p-2"
                                        rows={2}
                                    />
                                ) : (
                                    <p>{barangay?.location || 'Loading...'}</p>
                                )}
                            </div>

                            {/* Population Section */}
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold">Population</h3>
                                <p>
                                    The household population of Barangay 52-Ipil in the 2015 Census was 1,095 broken down into 306 households or an average of 3.58 members per household. Its population as determined by the 2020 Census was 1,174. This represented 1.17% of the total population of Cavite City.
                                    <br />
                                    The registered resident in the database {barangay?.population === 1 ? 'is' : 'are'} {barangay?.population || 'Loading...'} and the total household registered in the database {barangay?.household === 1 ? 'is' : 'are'} {barangay?.household || 'Loading...'}.
                                </p>
                            </div>

                            {/* Vicinity Map Section */}
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold">Vicinity Map</h3>
                                <img src={VicinityMap} alt="Vicinity Map" className="w-96 h-auto object-cover" />
                            </div>

                            {/* Edit/Save/Cancel Buttons */}
                            <div className="flex justify-end space-x-4">
                                {isEditing ? (
                                    <>
                                        <button
                                            onClick={handleSaveClick}
                                            className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition"
                                        >
                                            Save
                                        </button>
                                        <button
                                            onClick={handleCancelClick}
                                            className="bg-gray-400 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition"
                                        >
                                            Cancel
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        onClick={handleEditClick}
                                        className="bg-[#1346AC] text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition"
                                    >
                                        Edit Information
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default BarangayInformation
