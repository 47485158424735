import React, { useEffect, useState } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ResidentHeader from '../../../component/Resident/ResidentHeader';
import ResidentNav from '../../../component/Resident/ResidentNav';
import defaultImage from '../../../assets/icons/default-profile.png';

const ResidentBarangayDirectory = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [residentData, setResidentData] = useState(null);
    const [selection, setSelection] = useState([]);
    const [data, setData] = useState(null);

    // Fetch admins data from the backend
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/officials`);
                const admins = response.data;
    
                if (Array.isArray(admins)) {
                    // Process the admins to create a hierarchical structure
                    const hierarchy = buildHierarchy(admins);
                    setData(hierarchy);
                } else {
                    console.error('Invalid data format: Expected an array of admins');
                }
            } catch (error) {
                console.error('Error fetching admins:', error);
            }
        };
    
        fetchData();
    }, []);
    

    // Function to build the hierarchy
    const buildHierarchy = (admins) => {
        // Separate admins by role
        const barangayCaptain = admins.find(admin => admin.roleinBarangay === 'Barangay Captain');
        const secretary = admins.find(admin => admin.roleinBarangay === 'Secretary');
        const kagawads = admins
            .filter(admin => admin.roleinBarangay === 'Kagawad')
            .sort((a, b) => a.adminID.localeCompare(b.adminID)); // Sort kagawads by adminID

        // Structure the hierarchy with conditional checks
        return [
            {
                expanded: true,
                type: 'person',
                className: 'bg-white text-black',
                style: { borderRadius: '8px', padding: '10px' },
                data: {
                    image: barangayCaptain?.profilepic || defaultImage,
                    name: barangayCaptain ? `${barangayCaptain.firstName} ${barangayCaptain.lastName}` : 'No Barangay Captain',
                    title: 'Barangay Captain'
                },
                children: [
                    {
                        expanded: true,
                        type: 'person',
                        className: 'bg-white text-black',
                        style: { borderRadius: '8px', padding: '10px' },
                        data: {
                            image: secretary?.profilepic || defaultImage,
                            name: secretary ? `${secretary.firstName} ${secretary.lastName}` : 'No Secretary',
                            title: 'Secretary'
                        }
                    },
                    ...kagawadHierarchy(kagawads) // Append the kagawad hierarchy
                ]
            }
        ];
    };

    // Recursive function to build kagawad hierarchy based on adminID
    const kagawadHierarchy = (kagawads) => {
        if (kagawads.length === 0) {
            return [{
                expanded: true,
                type: 'person',
                className: 'bg-white text-black',
                style: { borderRadius: '8px', padding: '10px' },
                data: {
                    image: defaultImage,
                    name: 'No Kagawad',
                    title: 'Kagawad'
                }
            }];
        }

        const [firstKagawad, ...remainingKagawads] = kagawads;

        return [
            {
                expanded: true,
                type: 'person',
                className: 'bg-white text-black',
                style: { borderRadius: '8px', padding: '10px' },
                data: {
                    image: firstKagawad.profilepic || defaultImage,
                    name: `${firstKagawad.firstName} ${firstKagawad.lastName}`,
                    title: 'Kagawad'
                },
                children: kagawadHierarchy(remainingKagawads) // Recursively build hierarchy for remaining kagawads
            }
        ];
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, '/');
            }
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? `${capitalizeWords(user.middleName.charAt(0))}.` : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setResidentData(user);
            setUserRole(user.roleinHousehold);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    const nodeTemplate = (node) => {
        if (node.type === 'person') {
            return (
                <div className="flex flex-col p-4 border rounded shadow-md items-center" style={node.style}>
                    <img alt={node.data.name} src={node.data.image} className="mb-3 rounded-full w-16 h-16" />
                    <div className='flex-col flex'>
                        <span className="font-bold mb-2">{node.data.name}</span>
                        <span className="flex-col">{node.data.title}</span>
                    </div>
                </div>
            );
        }
        return (
            <div className="flex justify-center items-center p-2 border rounded-md" style={node.style}>
                {node.label}
            </div>
        );
    };

    return (
        <div className="flex flex-col min-h-screen">
            <ResidentHeader 
                userName={userName} 
                userRole={userRole} 
                handleLogout={handleLogout} 
                profilePic={residentData?.profilepic} 
            />
            <div className="flex flex-1">
            <ResidentNav residentData={residentData} />
            <main className='flex-1 p-8'>
                <div className="card overflow-x-auto w-full flex justify-center">
                    {data ? (
                        <OrganizationChart
                            value={data}
                            selectionMode="multiple"
                            selection={selection}
                            onSelectionChange={(e) => setSelection(e.data)}
                            nodeTemplate={nodeTemplate}
                        />
                    ) : (
                        <div>Loading...</div>
                    )}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ResidentBarangayDirectory;
